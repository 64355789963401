.services-b2b {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 10rem 0 14rem 0;

    @include respond(tab-land) {
        padding: 10rem 0 12rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: none;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 4rem;

        @include respond(tab-land) {
            margin-bottom: 3rem;
        }

        @include respond(phone) {
            margin-bottom: 2rem;
        }
    }

    &__text {
        font-family: 'Inter';
        max-width: 65.7rem;
        width: 100%;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: 8rem;

        @include respond(tab-land) {
            margin-top: 7rem;
        }

        @include respond(phone) {
            margin-top: 5rem;
        }

        &__item {
            display: flex;
            padding: 3rem 0;
            border-bottom: 1px solid $color-grey-3;

            &:first-child {
                border-top: 1px solid $color-grey-3;
            }

            @include respond(phone) {
                flex-direction: column;
                padding: 5rem 0;
            }

            &__info {
                display: flex;
                flex-direction: column;
                max-width: 36rem;
                width: 100%;

                @media only screen and (max-width: 52.125em) {
                    margin-right: 2rem;
                }

                @include respond(phone) {
                    margin-right: 0;
                }

                &__title {
                    margin-bottom: 3rem;

                    @include respond(phone) {
                        margin-bottom: 2rem;
                    }
                }

                &__text {
                    margin-bottom: 3rem;
                }
            }

            &__image {
                display: flex;
                margin-left: auto;
                max-width: 56rem;
                width: 100%;
                max-height: 30rem;
                height: 100%;

                @include respond(tab-land) {
                    max-width: 39.7rem;
                    max-height: 21.4rem;
                }

                @include respond(phone) {
                    max-width: none;
                    max-height: 20.4rem;
                    margin-top: 4rem;
                    margin-left: auto;
                    object-fit: cover;
                }
            }
        }
    }

    &--b2c {
        background: $color-grey-4;
        padding: 12rem 0 14rem 0;

        @include respond(tab-land) {
            padding: 10rem 0 12rem 0;
        }

        @include respond(phone) {
            padding: 6rem 0 8rem 0;
        }
    }
}
