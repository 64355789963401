.footer {
    display: flex;
    background: $color-black;
    color: $color-white;
    padding: 6rem 0;
    margin-top: auto;

    @include respond(tab-land) {
        padding: 4rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__logo {
        display: flex;
        align-items: center;
        text-decoration: none;

        svg {
            @include respond(phone) {
                width: 15.2rem;
                height: 2.8rem;
            }
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 2.3rem;

        @include respond(tab-land) {
            margin-right: 1.4rem;
        }

        @media only screen and (max-width: 52.125em) {
            flex-wrap: wrap;
            width: 100%;
            // gap: 3rem 5rem;
            margin-top: 2.5rem;
            margin-left: 0;
            margin-right: 0;

            .footer__navigation__item {
                margin: 1.5rem 0;
                margin-right: 5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @media only screen and (max-width: 31.875em) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 4rem;
            grid-row-gap: 3rem;
            max-width: 42rem;
            margin-top: 4rem;

            .footer__navigation__item {
                margin: 0;
            }
        }

        @media only screen and (max-width: 26.1875em) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;
        }

        &__item {
            display: flex;
            color: $color-white;
            margin-right: 5rem;

            @include respond(tab-land) {
                margin-right: 4rem;
            }

            @media only screen and (max-width: 52.125em) {
                margin: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .footer__navigation__item__link {
                    background-image: $gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: $color-blue;

                    &:hover {
                        color: transparent;
                    }
                }
            }

            &__link {
                font-size: 1.4rem;
                line-height: 2.1rem;
                font-weight: 700;
                color: inherit;
                text-decoration: none;
                transition: all 0.3s ease;
                background-image: $gradient;
                -webkit-background-clip: text;
                background-clip: text;
                color: $color-white;

                @include respond(phone) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }

                &:hover {
                    color: transparent;
                }
            }
        }
    }

    &__info {
        display: flex;
        width: 100%;
        color: $color-grey-5;
        font-family: 'Inter';
        padding-top: 6.7rem;
        margin-top: 6.7rem;
        border-top: 1px solid $color-grey-2;
        position: relative;

        @include respond(tab-land) {
            flex-wrap: wrap;
            padding-top: 4.7rem;
            margin-top: 4.7rem;
        }

        @media only screen and (max-width: 52.125em) {
            margin-top: 3.2rem;
        }

        @media only screen and (max-width: 31.875em) {
            margin-top: 4.7rem;
        }

        &::before {
            content: '';
            display: flex;
            align-items: center;
            width: 100%;
            height: 15px;
            position: absolute;
            top: -8px;
            left: 0;
            border-right: 1px solid $color-grey-2;
            border-left: 1px solid $color-grey-2;
        }

        &__copyright {
            max-width: 36rem;
            width: 100%;
            margin-right: 4rem;

            @include respond(tab-land) {
                max-width: 37.7rem;
            }

            @media only screen and (max-width: 52.125em) {
                margin-bottom: 2rem;
            }

            @include respond(phone) {
                max-width: unset;
                margin-right: 0;
            }
        }

        &__address {
            max-width: 16rem;
            width: 100%;
            margin-right: 4rem;

            @include respond(tab-land) {
                max-width: 36rem;
                margin-right: 1.7rem;
            }

            @include respond(phone) {
                max-width: unset;
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }

        &__text {
            max-width: 32rem;
            width: 100%;
            margin-right: 4rem;

            @include respond(tab-land) {
                max-width: 37.7rem;
                margin-top: 2rem;
            }

            @include respond(phone) {
                max-width: unset;
                margin-right: 0;
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            margin-left: auto;

            @include respond(tab-land) {
                margin-top: 2rem;
                margin-left: 0;
            }

            @include respond(phone) {
                flex-direction: row;
                align-items: center;
                margin-top: 0;
            }

            &__text {
                margin-bottom: 0.5rem;

                @include respond(phone) {
                    margin-bottom: 0;
                    margin-right: 2.5rem;
                }
            }

            &__link {
                display: flex;
                text-decoration: none;
            }
        }
    }
}
