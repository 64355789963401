.header {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    color: $color-white;
    z-index: 100;

    &.active {
        .container {
            max-width: 100%;

            @include respond(phone) {
                max-width: 100%;
            }
        }
        .header {
            &__mobile__navigation {
                transform: translateX(0);
            }

            &__logo {
                svg {
                    path {
                        &:nth-child(-n + 8) {
                            fill: $color-white;
                        }
                        &:nth-last-child(4) {
                            fill: $color-white;
                        }
                    }
                }
            }
        }
    }

    .container {
        transition: all 0.3s ease;
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        padding: 2.9rem 0;

        @include respond(tab-land) {
            padding: 2.5rem 0;
        }

        @include respond(phone) {
            padding: 1.8rem 0;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        height: 4.2rem;

        @include respond(tab-land) {
            height: 3rem;
            z-index: 5;
        }

        @include respond(phone) {
            height: 2.8rem;
        }

        svg {
            @include respond(tab-land) {
                width: 16.7rem;
                height: 3rem;
            }

            @include respond(phone) {
                width: 15.2rem;
                height: 2.8rem;
            }

            path {
                transition: all 0.3s ease;
            }
        }
    }

    &__navigation {
        display: flex;
        margin-left: auto;
        margin-right: 7.6rem;

        @include respond(tab-land) {
            display: none;
        }

        &__item {
            display: flex;
            color: $color-white;
            margin-right: 6rem;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .header__navigation__item__link {
                    background-image: $gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: $color-blue;

                    &:hover {
                        color: transparent;
                    }
                }
            }

            &__link {
                font-size: 1.4rem;
                line-height: 2.1rem;
                font-weight: 700;
                text-decoration: none;
                transition: all 0.3s ease;
                background-image: $gradient;
                -webkit-background-clip: text;
                background-clip: text;
                color: $color-white;

                &:hover {
                    color: transparent;
                }
            }
        }
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        position: relative;

        @include respond(tab-land) {
            display: none;
        }

        &.active {
            .header__search {
                &__btn {
                    background: $color-blue;
                }
                &__input {
                    display: flex;
                    width: 71.8rem;
                    right: 4.2rem;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            width: 4.2rem;
            height: 4.2rem;
            border: none;
            outline: none;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            z-index: 2;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
                opacity: 0;
                transition: all 0.3s ease;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }

        &__input {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.9rem;
            border: none;
            width: 4.2rem;
            height: 4.2rem;
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 2rem;
            cursor: default;
            transition: all 0.3s ease;
            opacity: 0;
            visibility: hidden;

            &::placeholder {
                color: $color-grey-5;
            }

            &:focus {
                cursor: text;
            }
        }
    }

    &__mobile {
        display: none;
        margin-left: auto;

        @include respond(tab-land) {
            display: flex;
        }

        &__navigation {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-black;
            padding: 18rem 2rem 0 2rem;
            overflow: auto;
            transform: translateX(200%);
            transition: all 0.3s ease;

            @include respond(phone) {
                padding: 10.4rem 2rem 0 2rem;
            }

            @media only screen and (max-height: 51.3125em) {
                padding: 12rem 2rem 0 2rem;
            }

            @media only screen and (max-height: 44) {
                padding: 9rem 2rem 0 2rem;
            }

            &__search {
                display: flex;
                &__input {
                    display: flex;
                    font-family: 'Inter';
                    font-weight: 400;
                    font-size: 1.8rem;
                    line-height: 2.9rem;
                    border: none;
                    width: calc(100% - 7rem);
                    height: 7rem;
                    padding: 0 2rem;
                    cursor: default;

                    @media only screen and (max-height: 56.25em) {
                        height: 5rem;
                        width: calc(100% - 5rem);
                    }

                    &::placeholder {
                        color: $color-grey-5;
                    }

                    &:focus {
                        cursor: text;
                    }
                }

                &__btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-blue;
                    width: 7rem;
                    height: 7rem;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    position: relative;
                    z-index: 2;

                    @media only screen and (max-height: 56.25em) {
                        width: 5rem;
                        height: 5rem;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -1;
                        background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
                        opacity: 0;
                        transition: all 0.3s ease;
                    }

                    &:hover {
                        &::after {
                            opacity: 1;
                        }
                    }

                    svg {
                        @media only screen and (max-height: 56.25em) {
                            height: 2rem;
                        }
                    }
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                margin-top: 9rem;

                @include respond(phone) {
                    margin-top: 2.3rem;
                }

                @media only screen and (max-height: 51.3125em) {
                    margin-top: 2rem;
                }

                &__item {
                    display: flex;
                    justify-content: flex-end;
                    padding: 3rem 0;
                    border-bottom: 1px solid $color-grey-2;
                    position: relative;

                    @include respond(phone) {
                        padding: 2.7rem 0;
                    }

                    @media only screen and (max-height: 51.3125em) {
                        padding: 2rem 0;
                    }

                    @media only screen and (max-height: 44em) {
                        padding: 1rem 0;
                    }

                    &:last-child {
                        border-bottom: none;

                        &::after {
                            content: none;
                        }
                    }

                    &::after {
                        content: '';
                        display: flex;
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        width: 100%;
                        height: 15px;
                        border-left: 1px solid $color-grey-2;
                        border-right: 1px solid $color-grey-2;
                    }

                    &.active {
                        .header__mobile__navigation__list__item__link {
                            background-image: $gradient;
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: $color-blue;

                            &:hover {
                                color: transparent;
                            }
                        }
                    }

                    &__link {
                        font-size: 4rem;
                        font-weight: 700;
                        line-height: 4.8rem;
                        text-decoration: none;
                        transition: all 0.3s ease;
                        background-image: $gradient;
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: $color-white;

                        @include respond(phone) {
                            font-size: 2.4rem;
                            line-height: 2.9rem;
                        }

                        @media only screen and (max-height: 75em) {
                            font-size: 2.9rem;
                            line-height: 3.9rem;
                        }

                        @media only screen and (max-height: 44em) {
                            font-size: 2.4rem;
                            line-height: 4rem;
                        }

                        &:hover {
                            color: transparent;
                        }
                    }
                }
            }
        }
    }

    &--white {
        background: $color-white;
        color: $color-black;

        .header {
            &__navigation__item {
                color: $color-black;

                &__link {
                    color: $color-black;

                    &:hover {
                        color: transparent;
                    }
                }
            }

            &__logo {
                svg {
                    path {
                        &:nth-child(-n + 8) {
                            fill: $color-black;
                        }
                        &:nth-last-child(4) {
                            fill: $color-black;
                        }
                    }
                }
            }

            &__search {
                &.active {
                    path {
                        stroke: $color-white;
                    }
                }

                &__btn {
                    &:hover {
                        path {
                            stroke: $color-white;
                        }
                    }

                    path {
                        stroke: $color-black;
                        transition: all 0.3s ease;
                    }
                }

                &__input {
                    border: 1px solid $color-grey-3;
                    border-right: none;
                }
            }
        }

        .btn-hamburger {
            &.active {
                & > span {
                    background: $color-white;

                    &::before,
                    &::after {
                        background: $color-white;
                    }
                }
            }
            & > span {
                background: $color-black;

                &::before,
                &::after {
                    background: $color-black;
                }
            }
        }
    }
}
