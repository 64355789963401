.pagination-slider {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 12rem 0 14rem 0;
    overflow-x: hidden;

    @include respond(tab-land) {
        padding: 10rem 0 12rem 0;
    }

    @include respond(phone) {
        padding: 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .swiper {
        margin-top: 6rem;

        @include respond(tab-land) {
            margin-top: 3rem;
        }

        @include respond(phone) {
            margin-top: 2rem;
        }

        &-pagination {
            display: flex;
            position: unset;
            margin-top: 4rem;
            border-top: 2px solid $color-grey-3;

            @include respond(tab-land) {
                overflow-x: scroll;
                padding-bottom: 2rem;
            }

            &-bullet {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 3rem;
                font-weight: 700;
                line-height: 3.6rem;
                background: $color-white;
                width: 16.8rem;
                height: 5.6rem;
                border-radius: 0;
                padding-top: 1.8rem;
                margin: 0 !important;
                opacity: 1 !important;
                transition: all 0.3s ease;
                outline: none;
                position: relative;
                user-select: none;

                @include respond(tab-land) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    min-width: 16.8rem;
                    height: 4.6rem;
                    padding-top: 2.2rem;
                }

                @include respond(phone) {
                    min-width: 13.4rem;
                    height: 5.2rem;
                    padding-top: 2.8rem;
                }

                span {
                    color: $color-grey-3;
                    background-image: $gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                    transition: all 0.3s ease;
                }

                &-active {
                    span {
                        color: $color-blue;
                    }
                }

                &:hover {
                    span {
                        color: transparent;
                    }
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 2px;
                    height: 2.8rem;
                    background: $color-grey-3;
                }
            }
        }

        &-control {
            margin-left: auto;
        }

        &-slide {
            .pagination-slider__slide {
                opacity: 0;
            }

            &-active {
                .pagination-slider__slide {
                    opacity: 1;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    &__slide {
        display: flex;

        @include respond(phone) {
            flex-direction: column;
        }

        &__image {
            max-width: 56rem;
            width: 100%;
            height: 40rem;
            margin-right: 4rem;

            @include respond(tab-land) {
                max-width: 37.7rem;
                object-fit: cover;
            }

            @include respond(phone) {
                max-width: 100%;
                height: 27.2rem;
                margin-right: 0;
                margin-bottom: 4rem;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;

            &__title {
                margin-bottom: 3rem;

                @include respond(tab-land) {
                    margin-bottom: 2rem;
                }

                @include respond(phone) {
                    font-size: 2.4rem;
                    line-height: 2.9rem;
                    margin-bottom: 3rem;
                }
            }

            &__text {
                font-family: 'Inter';
                margin-bottom: 3rem;
            }

            .btn-link {
                margin-top: auto;
            }
        }
    }
}
