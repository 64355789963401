.projects {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 12rem 0;
    overflow: hidden;

    @include respond(tab-land) {
        padding: 10rem 0 5rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 3rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        max-width: 36rem;
        width: 100%;

        @include respond(tab-land) {
            font-size: 4.8rem;
            line-height: 5.8rem;
            max-width: none;
        }

        @include respond(phone) {
            font-size: 3.6rem;
            line-height: 4.3rem;
        }
    }

    &__slider {
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 42rem;
        margin-top: -9.6rem;
        position: relative;
        user-select: none;

        &::before {
            content: '';
            display: flex;
            width: 100%;
            height: 1px;
            background: $color-grey-3;
            position: absolute;
            bottom: 17.9rem;
        }
        &::after {
            content: '';
            display: flex;
            width: 100%;
            height: 15px;
            background: transparent;
            border-left: 1px solid $color-grey-3;
            border-right: 1px solid $color-grey-3;
            position: absolute;
            bottom: 17.2rem;
        }

        @include respond(tab-land) {
            height: inherit;
            margin-top: 4rem;

            &::before,
            &::after {
                content: none;
            }
        }

        &__wrapper {
            display: flex;
            align-items: flex-end;

            @include respond(tab-land) {
                overflow-x: auto;
                overflow-y: hidden;
                padding-bottom: 5rem;
            }

            @include respond(phone) {
                padding-bottom: 3rem;
            }
        }

        .swiper-control {
            display: flex;
            position: absolute;
            top: calc(9.6rem + 4rem);
            left: 0;

            @include respond(tab-land) {
                display: none;
            }
        }

        &__slide {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-left: 6.1rem;
            position: relative;
            max-width: 8rem;
            width: 100%;
            height: 12rem;

            @include respond(tab-land) {
                max-width: unset;
                width: inherit;
                height: inherit;
            }

            @include respond(tab-land) {
                flex-direction: column;
                align-items: flex-start;
                margin-left: 4rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:nth-child(4) {
                max-width: unset;
                width: 28rem;
                height: 42rem;
                margin-left: 7.6rem;

                @include respond(tab-land) {
                    max-width: unset;
                    width: inherit;
                    height: inherit;
                    margin-left: 4rem;
                }

                & + .projects__slider__slide {
                    margin-left: 8rem;

                    @include respond(tab-land) {
                        margin-left: 4rem;
                    }
                }
            }

            &.hide {
                .projects__slider__slide {
                    &__info {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            &.active {
                // z-index: 5;

                &.animated {
                    .projects__slider__slide__info {
                        z-index: 5;
                    }
                }

                .projects__slider__slide {
                    &__image {
                        max-width: 28rem;
                        width: 100%;
                        height: 42rem;
                        transition: all 0.5s ease;
                    }

                    &__info {
                        animation: visibleSliderText 1s;
                        animation-delay: 0.3s;
                        z-index: -1;

                        // Google Chrome
                        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                            opacity: 1;
                            visibility: visible;
                            transition-delay: 0.3s;
                        }
                    }
                }
            }

            &__image {
                max-width: 8rem;
                width: 100%;
                height: 12rem;

                @include respond(tab-land) {
                    max-width: 23.8rem;
                    height: 35.7rem;
                }

                @include respond(phone) {
                    max-width: 22rem;
                    height: 33rem;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                width: 36rem;
                position: absolute;
                top: 0;
                left: calc(100% + 8rem);

                // Google Chrome
                @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                    opacity: 0;
                    visibility: hidden;
                }

                @include respond(tab-land) {
                    width: 23.8rem;
                    position: unset;
                    margin-top: 2rem;

                    // Google Chrome
                    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                @include respond(phone) {
                    width: 22rem;
                }

                &__num {
                    font-family: 'Inter';
                    color: $color-grey-1;
                    margin-bottom: 1rem;
                }

                &__title {
                    max-width: 35.5rem;
                    width: 100%;
                    margin-bottom: 3rem;

                    @include respond(tab-land) {
                        margin-bottom: 2rem;
                    }

                    @include respond(phone) {
                        font-size: 2rem;
                        line-height: 2.4rem;
                        max-width: 20rem;
                    }
                }
            }
        }
    }
}
