.cta {
    display: flex;
    background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
    color: $color-white;
    padding: 6rem 0 8rem 0;

    &__inner {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 2rem;
    }

    &__text {
        font-family: 'Inter';
        text-align: center;
        max-width: 62rem;
        width: 100%;
        margin-bottom: 5rem;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 36rem;
        width: 100%;
        height: 7rem;
        background: $color-black;
        text-decoration: none;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            opacity: 0;
            transition: all 0.3s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        &__text {
            font-family: 'Inter';
            font-size: 1.6rem;
            font-weight: 800;
            line-height: 2.1rem;
            text-transform: uppercase;
            margin-right: 1rem;
            z-index: 2;
            color: $color-white;

            @include respond(phone) {
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
        }

        svg {
            z-index: 2;
            path {
                stroke: $color-white;
                transition: all 0.3s ease;
            }
        }
    }
}
