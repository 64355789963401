.promo {
    display: flex;
    padding: 12rem 0 14rem 0;
    background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
    color: $color-white;

    @include respond(tab-land) {
        padding: 10rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 9rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        justify-content: flex-end;

        h4 {
            max-width: 69rem;
            width: 100%;
            margin-right: 7rem;

            @include respond(tab-land) {
                max-width: 41rem;
                margin-right: 10.6rem;
            }

            @include respond(phone) {
                max-width: none;
                margin-right: 2rem;
                font-size: 2.4rem;
                line-height: 2.9rem;
            }
        }
    }

    &--large {
        padding: 10rem 0 12rem 0;

        @include respond(phone) {
            padding: 6rem 0 8rem 0;
        }

        h4 {
            max-width: 76rem;
            margin-right: 0;

            @include respond(tab-land) {
                max-width: 41rem;
                margin-right: 10.6rem;
            }

            @include respond(phone) {
                max-width: none;
                margin-right: 0;
            }
        }
    }
}
