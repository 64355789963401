.services {
    display: flex;
    padding: 12rem 0 14rem 0;
    color: $color-white;
    background: $color-black;

    @include respond(tab-land) {
        padding: 10rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__text {
        font-family: 'Inter';
        margin-top: 2rem;

        @include respond(phone) {
            display: none;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 36.5rem) 30rem;
        grid-template-rows: repeat(3, 19.2rem);
        grid-column-gap: 6.5rem;
        grid-row-gap: 13.4rem;
        margin-top: 10rem;
        position: relative;

        @include respond(tab-land) {
            grid-template-columns: repeat(2, 39.7rem);
            grid-template-rows: 9.3rem 11.2rem repeat(2, 13.5rem);
            grid-column-gap: 0;
            grid-row-gap: 9.4rem;
            margin-top: 7rem;
        }

        @media only screen and (max-width: 52.125em) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond(phone) {
            display: flex;
            flex-direction: column;
            grid-template-columns: unset;
            grid-template-rows: unset;
            grid-column-gap: unset;
            grid-row-gap: unset;
            margin-top: 4rem;
        }

        &__item {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-right: 1px solid $color-grey-2;
            background: transparent;
            color: $color-white;
            text-decoration: none;
            position: relative;

            &:hover {
                .services__list__item {
                    &__title {
                        color: transparent;
                    }
                }

                .btn-link {
                    &__text {
                        color: transparent;
                    }

                    &__icon {
                        svg {
                            opacity: 0;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            @include respond(monitor) {
                &::before,
                &::after {
                    content: '';
                    display: flex;
                    width: 15px;
                    height: 1px;
                    background: $color-grey-2;
                    position: absolute;
                }

                &::before {
                    bottom: 0;
                    right: -8px;
                }

                &::after {
                    top: 0;
                    right: -8px;
                }

                &:nth-child(3n + 3),
                &:last-child {
                    border-right: none;
                    position: unset;

                    &::after,
                    &::before {
                        content: none;
                    }
                }

                &:not(.animated) {
                    &:nth-child(3) {
                        &::before,
                        &::after {
                            opacity: 1;
                        }
                    }
                    &:last-child {
                        &::before,
                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:nth-child(3) {
                    &::after {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        right: unset;
                        bottom: unset;
                        top: calc(19.2rem + 6.7rem);
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }

                    &::before {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 15px;
                        background: transparent;
                        position: absolute;
                        left: 0;
                        right: unset;
                        bottom: unset;
                        top: calc(19.2rem + 6rem);
                        border-right: 1px solid $color-grey-2;
                        border-left: 1px solid $color-grey-2;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }
                }

                &:last-child {
                    &::after {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 15px;
                        background: transparent;
                        position: absolute;
                        left: 0;
                        right: unset;
                        top: unset;
                        bottom: calc(19.2rem + 6rem);
                        border-right: 1px solid $color-grey-2;
                        border-left: 1px solid $color-grey-2;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }

                    &::before {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        right: unset;
                        top: unset;
                        bottom: calc(19.2rem + 6.7rem);
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }
                }
            }

            @include respond(tab-land) {
                &:nth-child(odd) {
                    &::before,
                    &::after {
                        content: '';
                        display: flex;
                        width: 15px;
                        height: 1px;
                        background: $color-grey-2;
                        position: absolute;
                    }

                    &::before {
                        bottom: 0;
                        right: -8px;
                    }

                    &::after {
                        top: 0;
                        right: -8px;
                    }
                }

                &:nth-child(2n + 2),
                &:last-child {
                    max-width: 27.8rem;
                    border-right: none;
                    margin-left: auto;

                    &::after {
                        content: '';
                        display: flex;
                        width: 79.4rem;
                        height: 1px;
                        background: $color-grey-2;
                        position: absolute;
                        bottom: -4.7rem;
                        right: 0;
                    }

                    &::before {
                        content: '';
                        display: flex;
                        width: 79.4rem;
                        height: 15px;
                        position: absolute;
                        bottom: -5.4rem;
                        right: 0;
                        border-right: 1px solid $color-grey-2;
                        border-left: 1px solid $color-grey-2;
                    }
                }

                &:last-child {
                    &::before,
                    &::after {
                        content: none;
                    }
                }
            }

            @include respond(phone) {
                border-bottom: 1px solid $color-grey-2;
                padding-bottom: 2.6rem;
                margin-bottom: 2.7rem;

                &:nth-child(2n + 2),
                &:last-child,
                &:nth-child(odd) {
                    max-width: none;
                    border-right: none;
                    margin-left: 0;

                    &::before {
                        content: none;
                    }

                    &::after {
                        content: '';
                        display: flex;
                        width: 100%;
                        height: 15px;
                        background: transparent;
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        top: unset;
                        right: unset;
                        border-left: 1px solid $color-grey-2;
                        border-right: 1px solid $color-grey-2;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;

                    &::after {
                        content: none;
                    }
                }
            }

            &__title {
                background-image: $gradient;
                -webkit-background-clip: text;
                background-clip: text;
                color: $color-white;
                text-decoration: none;
                max-width: 30rem;
                width: 100%;
                transition: all 0.3s ease;

                @include respond(phone) {
                    max-width: none;
                }
            }

            .btn-link {
                margin-top: auto;

                @include respond(phone) {
                    margin-top: 1rem;
                }
            }
        }
    }
}
