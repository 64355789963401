.heading {
    &-link {
        font-family: 'Inter';
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.1rem;
        text-transform: uppercase;

        @include respond(phone) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
    &-primary {
        font-size: 12rem;
        font-weight: 700;
        line-height: 10.8rem;

        @include respond(tab-land) {
            font-size: 8rem;
            line-height: 8rem;
        }

        @include respond(phone) {
            font-size: 6rem;
            line-height: 6rem;
        }
    }
    &-secondary {
        font-size: 9rem;
        font-weight: 700;
        line-height: 9rem;
    }
    &-tertiary {
        font-size: 7rem;
        font-weight: 700;
        line-height: 8.4rem;

        @include respond(tab-land) {
            font-size: 4.8rem;
            line-height: 5.8rem;
        }

        @include respond(phone) {
            font-size: 3.6rem;
            line-height: 4.3rem;
        }
    }
    &-fourth {
        font-size: 4rem;
        font-weight: 700;
        line-height: 4.8rem;

        @include respond(tab-land) {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }

        @include respond(phone) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }
    &-fifth {
        font-size: 3rem;
        font-weight: 700;
        line-height: 3.6rem;

        @include respond(tab-land) {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }

        @include respond(phone) {
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }
    &-sixth {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 2.9rem;

        @include respond(tab-land) {
            font-size: 2rem;
            line-height: 2.4rem;
        }

        @include respond(phone) {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }
    }
}

.paragraph {
    &-primary {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.9rem;

        @include respond(tab-land) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }
    &-secondary {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7rem;
    }
    &-tertiary {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.2rem;
    }
    &-fourth {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.6rem;
    }
}
