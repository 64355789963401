.btn {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.2rem;
    background: $color-black;
    color: $color-white;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;

    &-hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.4rem;
        height: 2.4rem;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        z-index: 5;

        @include respond(phone) {
            width: 2.6rem;
            height: 1.8rem;
        }

        &.active {
            & > span {
                background: $color-white;
                transform: rotate(225deg);

                &::before,
                &::after {
                    top: 0;
                    transform: rotate(90deg);
                }
            }
        }

        & > span {
            position: relative;
            flex: none;
            width: 100%;
            height: 3px;
            border-radius: 2px;
            background: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;

            @include respond(phone) {
                height: 2px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                top: -10px;
                right: 0;
                left: 0;
                width: 100%;
                height: 3px;
                border-radius: 2px;
                background: inherit;

                @include respond(phone) {
                    height: 2px;
                    top: -7.5px;
                }
            }

            &::after {
                top: 10px;

                @include respond(phone) {
                    top: 7.5px;
                }
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        width: fit-content;
        cursor: pointer;

        &:hover {
            .btn-link {
                &__text {
                    color: transparent;
                }

                &__icon {
                    svg {
                        opacity: 0;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        &__text {
            background-image: $gradient;
            -webkit-background-clip: text;
            background-clip: text;
            color: $color-blue;
            margin-right: 1rem;
            transition: all 0.3s ease;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &::after {
                content: url('/assets/images/link-hover.svg');
                display: flex;
                position: absolute;
                opacity: 0;
                height: 2.4rem;
                transition: all 0.3s ease;
            }

            svg {
                opacity: 1;
                transition: all 0.3s ease;
            }
        }

        &.btn-link--secondary {
            display: flex;
            align-items: center;
            max-width: 26rem;
            width: 100%;
            max-height: 26rem;
            position: relative;
            text-decoration: none;

            @include respond(monitor) {
                background: $color-blue;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
                    opacity: 0;
                    transition: all 0.3s ease;
                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }

                    .btn-link {
                        &__text {
                            color: $color-white;
                        }

                        &__icon {
                            &::after {
                                opacity: 0;
                            }
                            svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            @include respond(tab-land) {
                max-width: fit-content;
                width: unset;
                max-height: unset;
                margin-top: 4rem;
                transition: all 0.3s ease;

                &:hover {
                    .btn-link {
                        &__text {
                            background-image: $gradient;
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                        }

                        &__icon {
                            &::after {
                                opacity: 1;
                            }

                            svg {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            @include respond(phone) {
                margin-top: 0;

                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }

            .btn-link {
                &__block {
                    display: flex;
                    align-items: center;

                    @include respond(monitor) {
                        align-items: flex-start;
                        position: absolute;
                        left: 3rem;
                        bottom: 3rem;
                        z-index: 2;
                    }
                }

                &__text {
                    max-width: 10.5rem;
                    color: $color-white;
                    width: 100%;
                    margin-right: 1rem;
                    transition: all 0.3s ease;

                    @include respond(tab-land) {
                        background-image: $gradient;
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: $color-blue;
                        max-width: unset;
                        width: unset;
                    }
                }

                &__icon {
                    display: flex;

                    &::after {
                        content: url('/assets/images/link-hover.svg');
                        display: flex;
                        position: absolute;
                        opacity: 0;
                        height: 2.4rem;
                        transition: all 0.3s ease;

                        @include respond(phone) {
                            content: url('/assets/images/link-hover-small.svg');
                            height: 2rem;
                        }
                    }

                    svg {
                        opacity: 1;
                        transition: all 0.3s ease;
                        path {
                            stroke: $color-white;

                            @include respond(tab-land) {
                                stroke: $color-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

.swiper-control {
    display: flex;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-grey-4;
        width: 4.4rem;
        height: 4.4rem;
        cursor: pointer;
        transition: all 0.3s ease;
        outline: none;

        &:hover {
            background: $color-grey-1;

            path {
                fill: $color-white;
            }
        }

        &-prev {
            margin-right: 1rem;
        }

        path {
            fill: $color-black;
            transition: all 0.3s ease;
        }
    }
}
