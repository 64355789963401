// Colors
$color-white: #ffffff;
$color-black: #15212f;
$color-blue: #2d9cdb;
$color-green: #71bd4d;
$color-red: #f50d03;
$color-grey-1: #4d5e72;
$color-grey-2: #384757;
$color-grey-3: #c2cdd9;
$color-grey-4: #e1e7ed;
$color-grey-5: #788594;

$gradient: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
