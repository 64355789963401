@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes scale-down-center {
    0% {
        transform: scale(0.1);
        opacity: 0.2;
    }
    70% {
        opacity: 0.2;
    }
    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}

@keyframes scale-down-center-sec {
    0% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(0.8);
    }
}

@keyframes scale-down-center-third {
    0% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(0.6);
    }
}

@keyframes visibleSliderText {
    0% {
        display: none;
        z-index: 5;
        opacity: 0;
        transform: translateY(9rem);
    }
    100% {
        display: flex;
        z-index: 5;
        opacity: 1;
        transform: translateY(0);
    }
}
