.centered-slider {
    display: flex;
    flex-direction: column;
    background: $color-grey-4;
    color: $color-black;
    padding: 12rem 0 14rem 0;
    overflow: hidden;

    @include respond(tab-land) {
        padding: 10rem 0 12rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        position: relative;

        .swiper-control {
            position: absolute;
            top: 2rem;
            left: unset;
            right: 0;
            z-index: 10;

            @include respond(tab-land) {
                display: none;
            }

            &__btn {
                background: $color-white;

                &:hover {
                    background: $color-grey-1;
                }
            }
        }
    }

    &__title {
        max-width: 100rem;
        width: 100%;
        margin-bottom: 4rem;
        user-select: none;

        @include respond(tab-land) {
            max-width: unset;
            margin-bottom: 3rem;
        }
    }

    &__text {
        font-family: 'Inter';
        max-width: 56rem;
        width: 100%;
        margin-bottom: 5rem;
        user-select: none;

        @include respond(tab-land) {
            margin-bottom: 4rem;
        }
    }

    .swiper {
        width: 100%;
        height: 100%;

        &-wrapper {
            align-items: center;
        }

        &-slide {
            width: 76rem !important;
            height: 30rem;

            @include respond(tab-land) {
                width: 51.6rem !important;
                height: 25rem;
            }

            @include respond(phone) {
                width: 34rem !important;
                height: 17rem;
            }

            &-active {
                display: flex;
                align-items: center;
                height: 42.8rem;

                @include respond(tab-land) {
                    height: 29rem;
                }

                @include respond(phone) {
                    height: 19.1rem;
                }

                img {
                    width: 76rem !important;
                    height: 42.8rem;
                    transition: height 0.3s ease;

                    @include respond(tab-land) {
                        width: 51.6rem !important;
                        height: 29rem;
                    }

                    @include respond(phone) {
                        width: 34rem !important;
                        height: 19.1rem;
                    }
                }
            }
        }
    }

    &__slide {
        display: flex;

        img {
            width: 100%;
            height: 30rem;
            object-fit: cover;

            @include respond(tab-land) {
                height: 25rem;
            }

            @include respond(phone) {
                height: 17rem;
            }
        }
    }
}
