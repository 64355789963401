.consultation {
    display: flex;
    background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
    color: $color-white;
    padding: 8rem 0;

    @include respond(tab-land) {
        padding: 6rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;

        @include respond(phone) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    &__title {
        margin-bottom: 6rem;

        @include respond(tab-land) {
            margin-bottom: 4rem;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 58rem;
        width: 100%;
        height: 14rem;
        text-decoration: none;
        position: relative;

        @include respond(tab-land) {
            max-width: 39.7rem;
            height: 10rem;
        }

        @media only screen and (max-width: 52.125em) {
            max-width: 50%;
        }

        @include respond(phone) {
            max-width: none;
            height: 7rem;
        }

        &--primary {
            background: $color-black;
            color: $color-white;
        }

        &--secondary {
            background: $color-white;
            color: $color-black;
            transition: all 0.3s ease;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            opacity: 0;
            transition: all 0.3s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
            }

            &.consultation__link--secondary {
                color: $color-white;

                svg {
                    path {
                        stroke: $color-white;
                    }
                }
            }
        }

        &__text {
            font-family: 'Inter';
            font-size: 1.6rem;
            font-weight: 800;
            line-height: 2.1rem;
            text-transform: uppercase;
            margin-right: 1rem;
            z-index: 2;

            @include respond(phone) {
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
        }

        svg {
            z-index: 2;
            path {
                transition: all 0.3s ease;
            }
        }
    }
}
