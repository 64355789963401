.team-hero {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 9rem 0 5rem 0;
    margin-top: 10rem;

    @include respond(tab-land) {
        padding: 7rem 0 3rem 0;
        margin-top: 8rem;
    }

    @include respond(phone) {
        padding: 5rem 0 3rem 0;
        margin-top: 6.4rem;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;

        @include respond(phone) {
            flex-direction: column;
        }
    }

    &__title {
        max-width: 29rem;
        width: 100%;
        margin-top: 0.8rem;
        margin-right: 11rem;

        @include respond(tab-land) {
            max-width: 16rem;
            margin-top: 2.3rem;
            margin-right: 11.8rem;
        }

        @include respond(phone) {
            max-width: unset;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        &__title {
            max-width: 76rem;
            width: 100%;
            color: $color-blue;
            margin-bottom: 6rem;

            @include respond(tab-land) {
                max-width: 50.5rem;
                margin-bottom: 4rem;
            }

            @include respond(phone) {
                max-width: unset;
                margin-bottom: 3rem;
            }
        }

        &__text {
            font-family: 'Inter';
            max-width: 56rem;
            width: 100%;

            @include respond(tab-land) {
                max-width: 50.5rem;
            }
        }
    }
}

.team-list {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 0 0 12rem 0;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        // gap: 7rem 4rem;

        @media only screen and (max-width: 52.125em) {
            justify-content: center;
        }

        @include respond(phone) {
            justify-content: unset;
            // gap: 5rem 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        max-width: 36rem;
        width: 100%;
        position: relative;
        margin-top: 7rem;
        margin-right: 4rem;

        &:nth-child(3n) {
            margin-right: 0;
        }

        @include respond(tab-land) {
            &:nth-child(even) {
                margin-right: 0;
            }

            &:nth-child(odd) {
                margin-right: 4rem;
            }
        }

        @include respond(phone) {
            margin-top: 5rem;
            &:nth-child(odd) {
                margin-right: 0;
            }
        }

        .team-list__item__popup {
            transform: translateX(0);
            opacity: 1;
            transition: all 0.3s ease;
        }

        @include respond(monitor) {
            &:nth-child(3n-1) {
                .team-list__item__popup {
                    transform: translateX(calc(-36rem - 4rem));
                    opacity: 1;
                    transition: all 0.3s ease;
                }
            }

            &:nth-child(4n-1) {
                .team-list__item__popup {
                    transform: translateX(calc(-72rem - 8rem));
                    opacity: 1;
                    transition: all 0.3s ease;
                }
            }
        }

        @include respond(tab-land) {
            max-width: 37.7rem;

            &:nth-child(even) {
                .team-list__item__popup {
                    transform: translateX(calc(-37.7rem - 4rem));
                    opacity: 1;
                    transition: all 0.3s ease;
                }
            }
        }

        @media only screen and (max-width: 52.125em) {
            max-width: 34.4rem;

            &:nth-child(even) {
                .team-list__item__popup {
                    transform: translateX(calc(-100% + 50% - 2rem));
                }
            }
        }

        @include respond(phone) {
            max-width: 38rem;

            &:nth-child(even) {
                .team-list__item__popup {
                    transform: translateX(0);
                    opacity: 1;
                    transition: all 0.3s ease;
                }
            }

            &.active {
                background: $color-grey-4;
                padding: 2rem;

                .team-list__item {
                    &__image {
                        order: 1;
                        margin-bottom: 4rem;
                    }

                    &__name {
                        order: 2;
                        margin-bottom: 2rem;
                    }

                    &__subname {
                        order: 3;
                        margin-bottom: 4rem;
                    }

                    &__popup__close {
                        top: -11.4rem;
                        right: 0;
                    }
                }

                .popup-wrapper {
                    order: 4;
                }
            }
        }

        &__image {
            max-width: 36rem;
            width: 100%;
            max-height: 36rem;
            height: 100%;
            margin-bottom: 3rem;
            cursor: pointer;

            @include respond(tab-land) {
                max-width: 37.7rem;
                max-height: 37.7rem;
                margin-bottom: 2rem;
            }

            @include respond(phone) {
                max-width: 38rem;
                max-height: 38rem;
            }
        }

        &__name {
            cursor: pointer;
        }

        &__subname {
            font-family: 'Inter';
            color: $color-grey-1;
        }

        .popup-wrapper {
            &:not(.active) {
                .team-list__item__popup {
                    transform: translateX(-500%);
                    opacity: 0;
                    transition: all 0.3s ease;
                }

                @include respond(phone) {
                    display: none;
                }
            }
        }

        &__popup {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            background: $color-grey-4;
            color: $color-black;
            padding: 5rem;
            z-index: 10;
            width: 116rem;

            @include respond(tab-land) {
                width: 79.4rem;
                padding: 4rem;
            }

            @media only screen and (max-width: 52.125em) {
                width: 72.8rem;
            }

            @include respond(phone) {
                position: unset;
                top: unset;
                left: unset;
                width: 100%;
                padding: 0;
            }

            .popup {
                display: flex;
            }

            &__image {
                display: flex;
                max-width: 35.5rem;
                width: 100%;
                max-height: 35.5rem;
                width: 100%;
                margin-right: 5rem;

                @include respond(tab-land) {
                    max-width: 37.5rem;
                    max-height: 37.5rem;
                    margin-right: 4rem;
                }

                @include respond(phone) {
                    display: none;
                }
            }

            &__block {
                display: flex;
                flex-direction: column;
                max-width: 55rem;
                width: 100%;

                @include respond(tab-land) {
                    max-width: 29.9rem;
                }
            }

            &__name {
                margin-bottom: 2rem;

                @include respond(tab-land) {
                    max-width: 24rem;
                }

                @include respond(phone) {
                    display: none;
                }
            }

            &__subname {
                font-family: 'Inter';
                margin-bottom: 5rem;

                @include respond(tab-land) {
                    margin-bottom: 4rem;
                }

                @include respond(phone) {
                    display: none;
                }
            }

            &__text {
                font-family: 'Inter';
                max-width: 50rem;
                width: 100%;
            }

            &__close {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4.4rem;
                height: 4.4rem;
                position: absolute;
                top: 5rem;
                right: 5rem;
                background: $color-white;
                color: $color-black;
                border-radius: 100%;
                border: none;
                outline: none;
                transition: all 0.3s ease;
                cursor: pointer;

                @include respond(tab-land) {
                    top: 4rem;
                    right: 4rem;
                }

                &:hover {
                    background: $color-grey-1;

                    path {
                        stroke: $color-white;
                    }
                }

                path {
                    stroke: $color-black;
                    transition: all 0.3s ease;
                }
            }
        }
    }
}
