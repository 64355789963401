*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%;

    @include respond(big-desktop) {
        font-size: 75%;
    }
}

body {
    display: flex;
    flex-direction: column;
    font-family: 'Syne', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.6rem;
    color: $color-white;
    background-color: $color-black;
    min-height: 100vh;
    position: relative;

    &.no-scroll {
        overflow: hidden;
    }
}

.container {
    max-width: 116rem;
    width: 100%;
    margin: 0 auto;

    @include respond(tab-land) {
        max-width: 83.4rem;
        padding: 0 2rem;
    }

    @include respond(phone) {
        max-width: 42rem;
    }
}

.custom-cursor {
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 200;
    opacity: 0;
    transition: all 0.3s ease;

    @include respond(monitor) {
        display: flex;
    }

    &.active {
        opacity: 1;
    }

    &__border {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14.2rem;
        height: 14.2rem;
        position: fixed;
        pointer-events: none;
        left: 0;
        top: 0;
        transform: translate(-50%, -50%);

        &::after {
            content: url('/assets/images/cursor-circle-big.svg');
            position: absolute;
            height: 14.2rem;
        }
    }

    &__point {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.8rem;
        height: 2.8rem;
        position: fixed;
        transform: translate(-50%, -50%);
        pointer-events: none;
        transition: width 0.3s, height 0.3s;

        &::after {
            content: url('/assets/images/cursor-circle-small.svg');
            position: absolute;
            height: 2.8rem;
        }
    }
}

input {
    -webkit-appearance: none;
    border-radius: 0;
}

::selection {
    background-color: $color-blue;
    color: $color-white;
}
