.hero {
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/assets/images/background-hero.webp');
    padding-bottom: 8.5rem;
    position: relative;
    overflow: hidden;

    @include respond(tab-land) {
        padding-bottom: 6.9rem;
    }

    @include respond(phone) {
        padding: 11.2rem 0 12.6rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;

        @include respond(phone) {
            display: none;
        }
    }

    &__inner {
        display: flex;
        position: relative;

        @include respond(phone) {
            flex-direction: column;
        }
    }

    &__title {
        max-width: 30rem;
        width: 100%;
        margin-top: 19.8rem;
        margin-right: 9.4rem;
        color: $color-white;

        @include respond(tab-land) {
            max-width: 18rem;
            margin-top: 15.9rem;
            margin-right: 9.8rem;
        }

        @include respond(phone) {
            margin: 0;
            max-width: none;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-top: 19rem;

        @include respond(tab-land) {
            margin-top: 14.9rem;
        }

        @include respond(phone) {
            margin-top: 1rem;
        }

        &__title {
            color: $color-blue;
            margin-bottom: 4rem;

            @include respond(phone) {
                margin-bottom: 3rem;
            }
        }

        &__text {
            font-family: 'Inter';
            max-width: 56rem;
            width: 100%;
            color: $color-white;
            margin-bottom: 14.1rem;

            @include respond(tab-land) {
                max-width: 38rem;
                margin-bottom: 10.1rem;
            }

            @include respond(phone) {
                max-width: 56rem;
                margin-bottom: 4rem;
            }
        }
    }

    &__label {
        display: flex;
        position: absolute;
        bottom: -0.5rem;
        right: 0;

        @include respond(tab-land) {
            right: 2.5rem;
        }

        @include respond(phone) {
            position: unset;
            margin-top: 11.5rem;
        }

        &__text {
            max-width: 7.5rem;
            width: 100%;
            font-family: 'Inter';
            color: $color-white;
            margin-right: 1rem;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &::after {
                display: flex;
                content: url('/assets/images/star.svg');
                height: 10.3rem;
                width: 10.3rem;
                position: absolute;
                margin: auto;
                animation: rotate 10s linear infinite;
            }
        }

        img {
            display: flex;
            width: 5rem;
            height: 3.1rem;
        }
    }

    &__image {
        display: flex;
        max-width: 116rem;
        width: 100%;
        max-height: 46.6rem;
        height: 100%;
        margin-top: 9rem;

        @include respond(tab-land) {
            margin-top: 7rem;
        }

        @include respond(phone) {
            margin-top: 5rem;
        }
    }

    &--services {
        background: $color-black;
        padding-bottom: 14rem;

        @include respond(tab-land) {
            padding: 15rem 0 12rem 0;
        }

        @include respond(phone) {
            padding: 11.4rem 0 8rem 0;
        }

        .hero {
            &__inner {
                flex-wrap: wrap;
            }

            &__title {
                max-width: 29rem;
                margin-right: 11rem;

                @include respond(tab-land) {
                    max-width: 21.2rem;
                    margin-top: 2.2rem;
                    margin-right: 6.6rem;
                }

                @include respond(phone) {
                    max-width: none;
                    margin-top: 0;
                    margin-right: 0;
                }
            }

            &__info {
                max-width: calc(100% - 29rem - 11rem);

                @include respond(tab-land) {
                    max-width: calc(100% - 21.2rem - 6.6rem);
                    margin-top: 0;
                }

                @include respond(phone) {
                    max-width: none;
                    margin-top: 1rem;
                }

                &__title {
                    margin-bottom: 6rem;

                    @include respond(tab-land) {
                        margin-bottom: 4rem;
                    }

                    @include respond(phone) {
                        margin-bottom: 3rem;
                    }
                }

                &__text {
                    margin-bottom: 6rem;

                    @include respond(tab-land) {
                        max-width: 50.5rem;
                        margin-bottom: 4rem;
                    }

                    @include respond(phone) {
                        margin-bottom: 3rem;
                    }
                }
            }
        }
    }
}
