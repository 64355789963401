.search {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 9rem 0 12rem 0;
    margin-top: 10rem;

    @include respond(tab-land) {
        padding: 7rem 0 12rem 0;
        margin-top: 8rem;
    }

    @include respond(phone) {
        padding: 5rem 0 8rem 0;
        margin-top: 6.4rem;
    }

    .container {
        @include respond(phone) {
            max-width: none;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        color: $color-blue;
        margin-bottom: 4rem;

        @include respond(tab-land) {
            font-size: 7rem;
            line-height: 8.4rem;
            margin-bottom: 3rem;
        }

        @include respond(phone) {
            font-size: 3.6rem;
            line-height: 4.3rem;
        }
    }

    &__field {
        display: flex;
        max-width: 76rem;
        width: 100%;
        position: relative;

        &__input {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.9rem;
            border: none;
            background: $color-white;
            border: 2px solid $color-grey-3;
            border-right: none;
            width: calc(100% - 7rem);
            height: 7rem;
            padding: 0 3rem;
            cursor: default;

            &::placeholder {
                color: $color-grey-5;
            }

            &:focus {
                cursor: text;
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            width: 7rem;
            height: 7rem;
            background: $color-blue;
            border: none;
            outline: none;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            z-index: 2;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
                opacity: 0;
                transition: all 0.3s ease;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 36rem);
        gap: 7rem 4rem;
        margin-top: 9rem;

        @include respond(tab-land) {
            grid-template-columns: repeat(auto-fill, 37.7rem);
            margin-top: 7rem;
        }

        @media only screen and (max-width: 52.125em) {
            grid-template-columns: repeat(auto-fill, 34.4rem);
        }

        @include respond(phone) {
            grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
            gap: 5rem 2rem;
            margin-top: 5rem;
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 36rem;
            width: 100%;

            @include respond(tab-land) {
                max-width: 37.7rem;
            }

            @media only screen and (max-width: 52.125em) {
                max-width: 34.4rem;
            }

            @include respond(phone) {
                max-width: none;
            }

            &__title {
                margin-bottom: 3rem;
            }

            &__text {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 3rem;
            }
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        margin-top: 7rem;

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-grey-4;
            width: 4.4rem;
            height: 4.4rem;
            margin-left: 2rem;
            transition: all 0.3s ease;

            @include respond(phone) {
                margin-left: 1rem;

                &:nth-child(5),
                &:nth-child(6) {
                    display: none;
                }
            }

            &:first-child {
                margin-left: 0;

                & + .search__pagination__item {
                    margin-left: 6rem;

                    @include respond(phone) {
                        margin-left: 2rem;
                    }
                }
            }

            &:last-child {
                margin-left: 6rem;

                @include respond(phone) {
                    margin-left: 2rem;
                }
            }

            &:hover {
                background: $color-grey-1;

                .search__pagination__item__link {
                    color: $color-white;

                    path {
                        fill: $color-white;
                    }
                }
            }

            &--secondary {
                font-family: 'Inter';
                font-weight: 800;
                background: transparent;
                margin-left: 0;

                &:hover {
                    background: transparent;
                }

                & + .search__pagination__item {
                    margin-left: 0;
                }
            }

            &__link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-family: 'Inter';
                font-size: 1.6rem;
                font-weight: 800;
                line-height: 2.1rem;
                text-transform: uppercase;
                text-decoration: none;
                color: $color-black;
                transition: all 0.3s ease;

                &__content {
                    display: flex;

                    path {
                        fill: $color-black;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
}
