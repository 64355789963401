.team {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 12rem 0 10rem 0;

    @include respond(tab-land) {
        padding: 10rem 0 7rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 5rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }

        @media only screen and (max-width: 41.875em) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        max-width: 76rem;
        width: 100%;
        margin-bottom: 6rem;

        @include respond(tab-land) {
            margin-bottom: 4rem;
        }

        @media only screen and (max-width: 41.875em) {
            margin-bottom: 0;
        }
    }

    &__list {
        display: flex;

        @include respond(tab-land) {
            flex-wrap: wrap;
        }

        @include respond(phone) {
            .team__list__item {
                margin-right: 4rem;
            }

            .btn-link {
                margin-top: 4rem;
            }
        }

        @media only screen and (max-width: 41.875em) {
            max-width: 38rem;
            margin: 0 auto;

            .team__list__item {
                margin-top: 4rem;
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            max-width: 26rem;
            width: 100%;
            margin-right: 4rem;

            @include respond(tab-land) {
                max-width: 23.8rem;
                margin-right: 0;
                margin-left: 4rem;

                &:first-child,
                &:last-child {
                    margin-left: 0;
                }
            }

            @media only screen and (max-width: 52.125em) {
                margin-left: auto;
            }

            @include respond(phone) {
                max-width: 17rem;
                margin: 0;
            }

            @media only screen and (max-width: 26.1875em) {
                max-width: calc(50% - 2rem);
            }

            &:nth-last-child(2) {
                display: none;

                @media only screen and (max-width: 41.875em) {
                    display: flex;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &__image {
                display: flex;
                max-width: 26rem;
                width: 100%;
                max-height: 26rem;
                height: 100%;
                margin-bottom: 2rem;

                @include respond(tab-land) {
                    max-width: 23.8rem;
                    max-height: 23.8rem;
                    margin-bottom: 1rem;
                }

                @include respond(phone) {
                    max-width: 17rem;
                    max-height: 17rem;
                }
            }

            &__text {
                font-family: 'Inter';
                color: $color-grey-1;
            }

            &__name {
                @include respond(phone) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }
            }
        }
    }
}
