.about {
    display: flex;
    background-color: $color-white;
    color: $color-black;
    padding: 12rem 0;

    @include respond(tab-land) {
        padding: 10rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }
    &__title {
        color: $color-black;
        padding: 0 5rem 0 0;

        @include respond(tab-land) {
            padding: 0 3.5rem 0 0;
        }

        @include respond(phone) {
            padding: 0;
        }

        span {
            color: $color-blue;
        }
    }

    &__text {
        font-family: 'Inter';
        color: $color-grey-1;
        max-width: 56rem;
        width: 100%;
        margin: 6rem 20rem 0 auto;

        @include respond(tab-land) {
            max-width: 48rem;
            margin: 4rem 3.6rem 0 auto;
        }

        @include respond(phone) {
            margin: 3rem 0 0 0;
        }
    }
}
