@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes scale-down-center {
  0% {
    transform: scale(0.1);
    opacity: 0.2; }
  70% {
    opacity: 0.2; }
  100% {
    transform: scale(0.8);
    opacity: 0; } }

@keyframes scale-down-center-sec {
  0% {
    transform: scale(0.1); }
  100% {
    transform: scale(0.8); } }

@keyframes scale-down-center-third {
  0% {
    transform: scale(0.1); }
  100% {
    transform: scale(0.6); } }

@keyframes visibleSliderText {
  0% {
    display: none;
    z-index: 5;
    opacity: 0;
    transform: translateY(9rem); }
  100% {
    display: flex;
    z-index: 5;
    opacity: 1;
    transform: translateY(0); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  display: flex;
  flex-direction: column;
  font-family: 'Syne', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem;
  color: #ffffff;
  background-color: #15212f;
  min-height: 100vh;
  position: relative; }
  body.no-scroll {
    overflow: hidden; }

.container {
  max-width: 116rem;
  width: 100%;
  margin: 0 auto; }
  @media only screen and (max-width: 80em) {
    .container {
      max-width: 83.4rem;
      padding: 0 2rem; } }
  @media only screen and (max-width: 47.9375em) {
    .container {
      max-width: 42rem; } }

.custom-cursor {
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 200;
  opacity: 0;
  transition: all 0.3s ease; }
  @media only screen and (min-width: 80.0625em) {
    .custom-cursor {
      display: flex; } }
  .custom-cursor.active {
    opacity: 1; }
  .custom-cursor__border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14.2rem;
    height: 14.2rem;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%); }
    .custom-cursor__border::after {
      content: url("/assets/images/cursor-circle-big.svg");
      position: absolute;
      height: 14.2rem; }
  .custom-cursor__point {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width 0.3s, height 0.3s; }
    .custom-cursor__point::after {
      content: url("/assets/images/cursor-circle-small.svg");
      position: absolute;
      height: 2.8rem; }

input {
  -webkit-appearance: none;
  border-radius: 0; }

::selection {
  background-color: #2d9cdb;
  color: #ffffff; }

.btn {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.2rem;
  background: #15212f;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer; }
  .btn-hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.4rem;
    height: 2.4rem;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 5; }
    @media only screen and (max-width: 47.9375em) {
      .btn-hamburger {
        width: 2.6rem;
        height: 1.8rem; } }
    .btn-hamburger.active > span {
      background: #ffffff;
      transform: rotate(225deg); }
      .btn-hamburger.active > span::before, .btn-hamburger.active > span::after {
        top: 0;
        transform: rotate(90deg); }
    .btn-hamburger > span {
      position: relative;
      flex: none;
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease; }
      @media only screen and (max-width: 47.9375em) {
        .btn-hamburger > span {
          height: 2px; } }
      .btn-hamburger > span::before, .btn-hamburger > span::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -10px;
        right: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 2px;
        background: inherit; }
        @media only screen and (max-width: 47.9375em) {
          .btn-hamburger > span::before, .btn-hamburger > span::after {
            height: 2px;
            top: -7.5px; } }
      .btn-hamburger > span::after {
        top: 10px; }
        @media only screen and (max-width: 47.9375em) {
          .btn-hamburger > span::after {
            top: 7.5px; } }
  .btn-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: fit-content;
    cursor: pointer; }
    .btn-link:hover .btn-link__text {
      color: transparent; }
    .btn-link:hover .btn-link__icon svg {
      opacity: 0; }
    .btn-link:hover .btn-link__icon::after {
      opacity: 1; }
    .btn-link__text {
      background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
      -webkit-background-clip: text;
      background-clip: text;
      color: #2d9cdb;
      margin-right: 1rem;
      transition: all 0.3s ease; }
    .btn-link__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .btn-link__icon::after {
        content: url("/assets/images/link-hover.svg");
        display: flex;
        position: absolute;
        opacity: 0;
        height: 2.4rem;
        transition: all 0.3s ease; }
      .btn-link__icon svg {
        opacity: 1;
        transition: all 0.3s ease; }
    .btn-link.btn-link--secondary {
      display: flex;
      align-items: center;
      max-width: 26rem;
      width: 100%;
      max-height: 26rem;
      position: relative;
      text-decoration: none; }
      @media only screen and (min-width: 80.0625em) {
        .btn-link.btn-link--secondary {
          background: #2d9cdb; }
          .btn-link.btn-link--secondary::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            opacity: 0;
            transition: all 0.3s ease; }
          .btn-link.btn-link--secondary:hover::after {
            opacity: 1; }
          .btn-link.btn-link--secondary:hover .btn-link__text {
            color: #ffffff; }
          .btn-link.btn-link--secondary:hover .btn-link__icon::after {
            opacity: 0; }
          .btn-link.btn-link--secondary:hover .btn-link__icon svg {
            opacity: 1; } }
      @media only screen and (max-width: 80em) {
        .btn-link.btn-link--secondary {
          max-width: fit-content;
          width: unset;
          max-height: unset;
          margin-top: 4rem;
          transition: all 0.3s ease; }
          .btn-link.btn-link--secondary:hover .btn-link__text {
            background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent; }
          .btn-link.btn-link--secondary:hover .btn-link__icon::after {
            opacity: 1; }
          .btn-link.btn-link--secondary:hover .btn-link__icon svg {
            opacity: 0; } }
      @media only screen and (max-width: 47.9375em) {
        .btn-link.btn-link--secondary {
          margin-top: 0; }
          .btn-link.btn-link--secondary svg {
            width: 2rem;
            height: 2rem; } }
      .btn-link.btn-link--secondary .btn-link__block {
        display: flex;
        align-items: center; }
        @media only screen and (min-width: 80.0625em) {
          .btn-link.btn-link--secondary .btn-link__block {
            align-items: flex-start;
            position: absolute;
            left: 3rem;
            bottom: 3rem;
            z-index: 2; } }
      .btn-link.btn-link--secondary .btn-link__text {
        max-width: 10.5rem;
        color: #ffffff;
        width: 100%;
        margin-right: 1rem;
        transition: all 0.3s ease; }
        @media only screen and (max-width: 80em) {
          .btn-link.btn-link--secondary .btn-link__text {
            background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            -webkit-background-clip: text;
            background-clip: text;
            color: #2d9cdb;
            max-width: unset;
            width: unset; } }
      .btn-link.btn-link--secondary .btn-link__icon {
        display: flex; }
        .btn-link.btn-link--secondary .btn-link__icon::after {
          content: url("/assets/images/link-hover.svg");
          display: flex;
          position: absolute;
          opacity: 0;
          height: 2.4rem;
          transition: all 0.3s ease; }
          @media only screen and (max-width: 47.9375em) {
            .btn-link.btn-link--secondary .btn-link__icon::after {
              content: url("/assets/images/link-hover-small.svg");
              height: 2rem; } }
        .btn-link.btn-link--secondary .btn-link__icon svg {
          opacity: 1;
          transition: all 0.3s ease; }
          .btn-link.btn-link--secondary .btn-link__icon svg path {
            stroke: #ffffff; }
            @media only screen and (max-width: 80em) {
              .btn-link.btn-link--secondary .btn-link__icon svg path {
                stroke: #2d9cdb; } }

.swiper-control {
  display: flex; }
  .swiper-control__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e1e7ed;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none; }
    .swiper-control__btn:hover {
      background: #4d5e72; }
      .swiper-control__btn:hover path {
        fill: #ffffff; }
    .swiper-control__btn-prev {
      margin-right: 1rem; }
    .swiper-control__btn path {
      fill: #15212f;
      transition: all 0.3s ease; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

button,
input,
textarea,
select {
  font-family: 'Syne', sans-serif;
  -webkit-appearance: none;
  outline: none; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

main {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

.heading-link {
  font-family: 'Inter';
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.1rem;
  text-transform: uppercase; }
  @media only screen and (max-width: 47.9375em) {
    .heading-link {
      font-size: 1.4rem;
      line-height: 1.8rem; } }

.heading-primary {
  font-size: 12rem;
  font-weight: 700;
  line-height: 10.8rem; }
  @media only screen and (max-width: 80em) {
    .heading-primary {
      font-size: 8rem;
      line-height: 8rem; } }
  @media only screen and (max-width: 47.9375em) {
    .heading-primary {
      font-size: 6rem;
      line-height: 6rem; } }

.heading-secondary {
  font-size: 9rem;
  font-weight: 700;
  line-height: 9rem; }

.heading-tertiary {
  font-size: 7rem;
  font-weight: 700;
  line-height: 8.4rem; }
  @media only screen and (max-width: 80em) {
    .heading-tertiary {
      font-size: 4.8rem;
      line-height: 5.8rem; } }
  @media only screen and (max-width: 47.9375em) {
    .heading-tertiary {
      font-size: 3.6rem;
      line-height: 4.3rem; } }

.heading-fourth {
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.8rem; }
  @media only screen and (max-width: 80em) {
    .heading-fourth {
      font-size: 2.4rem;
      line-height: 2.9rem; } }
  @media only screen and (max-width: 47.9375em) {
    .heading-fourth {
      font-size: 2rem;
      line-height: 2.4rem; } }

.heading-fifth {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.6rem; }
  @media only screen and (max-width: 80em) {
    .heading-fifth {
      font-size: 2.4rem;
      line-height: 2.9rem; } }
  @media only screen and (max-width: 47.9375em) {
    .heading-fifth {
      font-size: 2rem;
      line-height: 2.4rem; } }

.heading-sixth {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem; }
  @media only screen and (max-width: 80em) {
    .heading-sixth {
      font-size: 2rem;
      line-height: 2.4rem; } }
  @media only screen and (max-width: 47.9375em) {
    .heading-sixth {
      font-size: 2.4rem;
      line-height: 2.9rem; } }

.paragraph-primary {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.9rem; }
  @media only screen and (max-width: 80em) {
    .paragraph-primary {
      font-size: 1.6rem;
      line-height: 2.6rem; } }

.paragraph-secondary {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem; }

.paragraph-tertiary {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem; }

.paragraph-fourth {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem; }

.hero {
  display: flex;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/images/background-hero.webp");
  padding-bottom: 8.5rem;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 80em) {
    .hero {
      padding-bottom: 6.9rem; } }
  @media only screen and (max-width: 47.9375em) {
    .hero {
      padding: 11.2rem 0 12.6rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .hero .container {
      max-width: unset; } }
  .hero video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%; }
    @media only screen and (max-width: 47.9375em) {
      .hero video {
        display: none; } }
  .hero__inner {
    display: flex;
    position: relative; }
    @media only screen and (max-width: 47.9375em) {
      .hero__inner {
        flex-direction: column; } }
  .hero__title {
    max-width: 30rem;
    width: 100%;
    margin-top: 19.8rem;
    margin-right: 9.4rem;
    color: #ffffff; }
    @media only screen and (max-width: 80em) {
      .hero__title {
        max-width: 18rem;
        margin-top: 15.9rem;
        margin-right: 9.8rem; } }
    @media only screen and (max-width: 47.9375em) {
      .hero__title {
        margin: 0;
        max-width: none; } }
  .hero__info {
    display: flex;
    flex-direction: column;
    margin-top: 19rem; }
    @media only screen and (max-width: 80em) {
      .hero__info {
        margin-top: 14.9rem; } }
    @media only screen and (max-width: 47.9375em) {
      .hero__info {
        margin-top: 1rem; } }
    .hero__info__title {
      color: #2d9cdb;
      margin-bottom: 4rem; }
      @media only screen and (max-width: 47.9375em) {
        .hero__info__title {
          margin-bottom: 3rem; } }
    .hero__info__text {
      font-family: 'Inter';
      max-width: 56rem;
      width: 100%;
      color: #ffffff;
      margin-bottom: 14.1rem; }
      @media only screen and (max-width: 80em) {
        .hero__info__text {
          max-width: 38rem;
          margin-bottom: 10.1rem; } }
      @media only screen and (max-width: 47.9375em) {
        .hero__info__text {
          max-width: 56rem;
          margin-bottom: 4rem; } }
  .hero__label {
    display: flex;
    position: absolute;
    bottom: -0.5rem;
    right: 0; }
    @media only screen and (max-width: 80em) {
      .hero__label {
        right: 2.5rem; } }
    @media only screen and (max-width: 47.9375em) {
      .hero__label {
        position: unset;
        margin-top: 11.5rem; } }
    .hero__label__text {
      max-width: 7.5rem;
      width: 100%;
      font-family: 'Inter';
      color: #ffffff;
      margin-right: 1rem; }
    .hero__label__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .hero__label__icon::after {
        display: flex;
        content: url("/assets/images/star.svg");
        height: 10.3rem;
        width: 10.3rem;
        position: absolute;
        margin: auto;
        animation: rotate 10s linear infinite; }
    .hero__label img {
      display: flex;
      width: 5rem;
      height: 3.1rem; }
  .hero__image {
    display: flex;
    max-width: 116rem;
    width: 100%;
    max-height: 46.6rem;
    height: 100%;
    margin-top: 9rem; }
    @media only screen and (max-width: 80em) {
      .hero__image {
        margin-top: 7rem; } }
    @media only screen and (max-width: 47.9375em) {
      .hero__image {
        margin-top: 5rem; } }
  .hero--services {
    background: #15212f;
    padding-bottom: 14rem; }
    @media only screen and (max-width: 80em) {
      .hero--services {
        padding: 15rem 0 12rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .hero--services {
        padding: 11.4rem 0 8rem 0; } }
    .hero--services .hero__inner {
      flex-wrap: wrap; }
    .hero--services .hero__title {
      max-width: 29rem;
      margin-right: 11rem; }
      @media only screen and (max-width: 80em) {
        .hero--services .hero__title {
          max-width: 21.2rem;
          margin-top: 2.2rem;
          margin-right: 6.6rem; } }
      @media only screen and (max-width: 47.9375em) {
        .hero--services .hero__title {
          max-width: none;
          margin-top: 0;
          margin-right: 0; } }
    .hero--services .hero__info {
      max-width: calc(100% - 29rem - 11rem); }
      @media only screen and (max-width: 80em) {
        .hero--services .hero__info {
          max-width: calc(100% - 21.2rem - 6.6rem);
          margin-top: 0; } }
      @media only screen and (max-width: 47.9375em) {
        .hero--services .hero__info {
          max-width: none;
          margin-top: 1rem; } }
      .hero--services .hero__info__title {
        margin-bottom: 6rem; }
        @media only screen and (max-width: 80em) {
          .hero--services .hero__info__title {
            margin-bottom: 4rem; } }
        @media only screen and (max-width: 47.9375em) {
          .hero--services .hero__info__title {
            margin-bottom: 3rem; } }
      .hero--services .hero__info__text {
        margin-bottom: 6rem; }
        @media only screen and (max-width: 80em) {
          .hero--services .hero__info__text {
            max-width: 50.5rem;
            margin-bottom: 4rem; } }
        @media only screen and (max-width: 47.9375em) {
          .hero--services .hero__info__text {
            margin-bottom: 3rem; } }

.about {
  display: flex;
  background-color: #ffffff;
  color: #15212f;
  padding: 12rem 0; }
  @media only screen and (max-width: 80em) {
    .about {
      padding: 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .about {
      padding: 6rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .about .container {
      max-width: unset; } }
  .about__inner {
    display: flex;
    flex-direction: column; }
  .about__title {
    color: #15212f;
    padding: 0 5rem 0 0; }
    @media only screen and (max-width: 80em) {
      .about__title {
        padding: 0 3.5rem 0 0; } }
    @media only screen and (max-width: 47.9375em) {
      .about__title {
        padding: 0; } }
    .about__title span {
      color: #2d9cdb; }
  .about__text {
    font-family: 'Inter';
    color: #4d5e72;
    max-width: 56rem;
    width: 100%;
    margin: 6rem 20rem 0 auto; }
    @media only screen and (max-width: 80em) {
      .about__text {
        max-width: 48rem;
        margin: 4rem 3.6rem 0 auto; } }
    @media only screen and (max-width: 47.9375em) {
      .about__text {
        margin: 3rem 0 0 0; } }

.services {
  display: flex;
  padding: 12rem 0 14rem 0;
  color: #ffffff;
  background: #15212f; }
  @media only screen and (max-width: 80em) {
    .services {
      padding: 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .services {
      padding: 6rem 0 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .services .container {
      max-width: unset; } }
  .services__inner {
    display: flex;
    flex-direction: column; }
  .services__text {
    font-family: 'Inter';
    margin-top: 2rem; }
    @media only screen and (max-width: 47.9375em) {
      .services__text {
        display: none; } }
  .services__list {
    display: grid;
    grid-template-columns: repeat(2, 36.5rem) 30rem;
    grid-template-rows: repeat(3, 19.2rem);
    grid-column-gap: 6.5rem;
    grid-row-gap: 13.4rem;
    margin-top: 10rem;
    position: relative; }
    @media only screen and (max-width: 80em) {
      .services__list {
        grid-template-columns: repeat(2, 39.7rem);
        grid-template-rows: 9.3rem 11.2rem repeat(2, 13.5rem);
        grid-column-gap: 0;
        grid-row-gap: 9.4rem;
        margin-top: 7rem; } }
    @media only screen and (max-width: 52.125em) {
      .services__list {
        grid-template-columns: repeat(2, 1fr); } }
    @media only screen and (max-width: 47.9375em) {
      .services__list {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        grid-column-gap: unset;
        grid-row-gap: unset;
        margin-top: 4rem; } }
    .services__list__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-right: 1px solid #384757;
      background: transparent;
      color: #ffffff;
      text-decoration: none;
      position: relative; }
      .services__list__item:hover .services__list__item__title {
        color: transparent; }
      .services__list__item:hover .btn-link__text {
        color: transparent; }
      .services__list__item:hover .btn-link__icon svg {
        opacity: 0; }
      .services__list__item:hover .btn-link__icon::after {
        opacity: 1; }
      @media only screen and (min-width: 80.0625em) {
        .services__list__item::before, .services__list__item::after {
          content: '';
          display: flex;
          width: 15px;
          height: 1px;
          background: #384757;
          position: absolute; }
        .services__list__item::before {
          bottom: 0;
          right: -8px; }
        .services__list__item::after {
          top: 0;
          right: -8px; }
        .services__list__item:nth-child(3n + 3), .services__list__item:last-child {
          border-right: none;
          position: unset; }
          .services__list__item:nth-child(3n + 3)::after, .services__list__item:nth-child(3n + 3)::before, .services__list__item:last-child::after, .services__list__item:last-child::before {
            content: none; }
        .services__list__item:not(.animated):nth-child(3)::before, .services__list__item:not(.animated):nth-child(3)::after {
          opacity: 1; }
        .services__list__item:not(.animated):last-child::before, .services__list__item:not(.animated):last-child::after {
          opacity: 1; }
        .services__list__item:nth-child(3)::after {
          content: '';
          display: flex;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          right: unset;
          bottom: unset;
          top: calc(19.2rem + 6.7rem);
          opacity: 0;
          transition: opacity 0.3s ease; }
        .services__list__item:nth-child(3)::before {
          content: '';
          display: flex;
          width: 100%;
          height: 15px;
          background: transparent;
          position: absolute;
          left: 0;
          right: unset;
          bottom: unset;
          top: calc(19.2rem + 6rem);
          border-right: 1px solid #384757;
          border-left: 1px solid #384757;
          opacity: 0;
          transition: opacity 0.3s ease; }
        .services__list__item:last-child::after {
          content: '';
          display: flex;
          width: 100%;
          height: 15px;
          background: transparent;
          position: absolute;
          left: 0;
          right: unset;
          top: unset;
          bottom: calc(19.2rem + 6rem);
          border-right: 1px solid #384757;
          border-left: 1px solid #384757;
          opacity: 0;
          transition: opacity 0.3s ease; }
        .services__list__item:last-child::before {
          content: '';
          display: flex;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          right: unset;
          top: unset;
          bottom: calc(19.2rem + 6.7rem);
          opacity: 0;
          transition: opacity 0.3s ease; } }
      @media only screen and (max-width: 80em) {
        .services__list__item:nth-child(odd)::before, .services__list__item:nth-child(odd)::after {
          content: '';
          display: flex;
          width: 15px;
          height: 1px;
          background: #384757;
          position: absolute; }
        .services__list__item:nth-child(odd)::before {
          bottom: 0;
          right: -8px; }
        .services__list__item:nth-child(odd)::after {
          top: 0;
          right: -8px; }
        .services__list__item:nth-child(2n + 2), .services__list__item:last-child {
          max-width: 27.8rem;
          border-right: none;
          margin-left: auto; }
          .services__list__item:nth-child(2n + 2)::after, .services__list__item:last-child::after {
            content: '';
            display: flex;
            width: 79.4rem;
            height: 1px;
            background: #384757;
            position: absolute;
            bottom: -4.7rem;
            right: 0; }
          .services__list__item:nth-child(2n + 2)::before, .services__list__item:last-child::before {
            content: '';
            display: flex;
            width: 79.4rem;
            height: 15px;
            position: absolute;
            bottom: -5.4rem;
            right: 0;
            border-right: 1px solid #384757;
            border-left: 1px solid #384757; }
        .services__list__item:last-child::before, .services__list__item:last-child::after {
          content: none; } }
      @media only screen and (max-width: 47.9375em) {
        .services__list__item {
          border-bottom: 1px solid #384757;
          padding-bottom: 2.6rem;
          margin-bottom: 2.7rem; }
          .services__list__item:nth-child(2n + 2), .services__list__item:last-child, .services__list__item:nth-child(odd) {
            max-width: none;
            border-right: none;
            margin-left: 0; }
            .services__list__item:nth-child(2n + 2)::before, .services__list__item:last-child::before, .services__list__item:nth-child(odd)::before {
              content: none; }
            .services__list__item:nth-child(2n + 2)::after, .services__list__item:last-child::after, .services__list__item:nth-child(odd)::after {
              content: '';
              display: flex;
              width: 100%;
              height: 15px;
              background: transparent;
              position: absolute;
              left: 0;
              bottom: -8px;
              top: unset;
              right: unset;
              border-left: 1px solid #384757;
              border-right: 1px solid #384757; }
          .services__list__item:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none; }
            .services__list__item:last-child::after {
              content: none; } }
      .services__list__item__title {
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #ffffff;
        text-decoration: none;
        max-width: 30rem;
        width: 100%;
        transition: all 0.3s ease; }
        @media only screen and (max-width: 47.9375em) {
          .services__list__item__title {
            max-width: none; } }
      .services__list__item .btn-link {
        margin-top: auto; }
        @media only screen and (max-width: 47.9375em) {
          .services__list__item .btn-link {
            margin-top: 1rem; } }

.services-b2b {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 10rem 0 14rem 0; }
  @media only screen and (max-width: 80em) {
    .services-b2b {
      padding: 10rem 0 12rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .services-b2b {
      padding: 6rem 0 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .services-b2b .container {
      max-width: none; } }
  .services-b2b__inner {
    display: flex;
    flex-direction: column; }
  .services-b2b__title {
    margin-bottom: 4rem; }
    @media only screen and (max-width: 80em) {
      .services-b2b__title {
        margin-bottom: 3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .services-b2b__title {
        margin-bottom: 2rem; } }
  .services-b2b__text {
    font-family: 'Inter';
    max-width: 65.7rem;
    width: 100%; }
  .services-b2b__list {
    display: flex;
    flex-direction: column;
    margin-top: 8rem; }
    @media only screen and (max-width: 80em) {
      .services-b2b__list {
        margin-top: 7rem; } }
    @media only screen and (max-width: 47.9375em) {
      .services-b2b__list {
        margin-top: 5rem; } }
    .services-b2b__list__item {
      display: flex;
      padding: 3rem 0;
      border-bottom: 1px solid #c2cdd9; }
      .services-b2b__list__item:first-child {
        border-top: 1px solid #c2cdd9; }
      @media only screen and (max-width: 47.9375em) {
        .services-b2b__list__item {
          flex-direction: column;
          padding: 5rem 0; } }
      .services-b2b__list__item__info {
        display: flex;
        flex-direction: column;
        max-width: 36rem;
        width: 100%; }
        @media only screen and (max-width: 52.125em) {
          .services-b2b__list__item__info {
            margin-right: 2rem; } }
        @media only screen and (max-width: 47.9375em) {
          .services-b2b__list__item__info {
            margin-right: 0; } }
        .services-b2b__list__item__info__title {
          margin-bottom: 3rem; }
          @media only screen and (max-width: 47.9375em) {
            .services-b2b__list__item__info__title {
              margin-bottom: 2rem; } }
        .services-b2b__list__item__info__text {
          margin-bottom: 3rem; }
      .services-b2b__list__item__image {
        display: flex;
        margin-left: auto;
        max-width: 56rem;
        width: 100%;
        max-height: 30rem;
        height: 100%; }
        @media only screen and (max-width: 80em) {
          .services-b2b__list__item__image {
            max-width: 39.7rem;
            max-height: 21.4rem; } }
        @media only screen and (max-width: 47.9375em) {
          .services-b2b__list__item__image {
            max-width: none;
            max-height: 20.4rem;
            margin-top: 4rem;
            margin-left: auto;
            object-fit: cover; } }
  .services-b2b--b2c {
    background: #e1e7ed;
    padding: 12rem 0 14rem 0; }
    @media only screen and (max-width: 80em) {
      .services-b2b--b2c {
        padding: 10rem 0 12rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .services-b2b--b2c {
        padding: 6rem 0 8rem 0; } }

.stats {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 12rem 0; }
  @media only screen and (max-width: 80em) {
    .stats {
      padding: 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .stats {
      padding: 6rem 0 5.5rem 0; } }
  .stats__inner {
    display: flex;
    flex-direction: column; }
  .stats__list {
    display: flex; }
    @media only screen and (max-width: 80em) {
      .stats__list {
        padding: 0 5.2rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats__list {
        flex-direction: column;
        padding: 0; } }
    .stats__list__item {
      display: flex;
      flex-direction: column;
      padding: 1.4rem 0 1.1rem 0;
      position: relative; }
      @media only screen and (max-width: 47.9375em) {
        .stats__list__item {
          padding: 0; } }
      .stats__list__item:nth-child(1) {
        align-items: flex-start; }
        @media only screen and (max-width: 47.9375em) {
          .stats__list__item:nth-child(1) {
            padding-bottom: 4.1rem; } }
      .stats__list__item:nth-child(3) {
        align-items: flex-end; }
        @media only screen and (max-width: 80em) {
          .stats__list__item:nth-child(3) {
            margin-left: auto; } }
        @media only screen and (max-width: 47.9375em) {
          .stats__list__item:nth-child(3) {
            padding-top: 4.6rem; } }
      .stats__list__item:nth-child(1), .stats__list__item:nth-child(3) {
        max-width: 36.5rem;
        width: 100%; }
        @media only screen and (max-width: 80em) {
          .stats__list__item:nth-child(1), .stats__list__item:nth-child(3) {
            max-width: 19.8rem; } }
        @media only screen and (max-width: 47.9375em) {
          .stats__list__item:nth-child(1), .stats__list__item:nth-child(3) {
            max-width: none;
            align-items: center; } }
        .stats__list__item:nth-child(1) .stats__list__item__num, .stats__list__item:nth-child(3) .stats__list__item__num {
          max-width: 16rem;
          width: 100%;
          justify-content: center; }
      .stats__list__item:nth-child(2) {
        align-items: center;
        max-width: 43rem;
        width: 100%; }
        @media only screen and (max-width: 80em) {
          .stats__list__item:nth-child(2) {
            max-width: 27.7rem; } }
        @media only screen and (max-width: 47.9375em) {
          .stats__list__item:nth-child(2) {
            max-width: none;
            padding: 4.7rem 0; } }
      .stats__list__item:not(:last-child) {
        border-right: 1px solid #c2cdd9; }
        @media only screen and (max-width: 47.9375em) {
          .stats__list__item:not(:last-child) {
            border-right: none;
            border-bottom: 1px solid #c2cdd9; } }
        .stats__list__item:not(:last-child)::before, .stats__list__item:not(:last-child)::after {
          content: '';
          display: flex;
          width: 15px;
          height: 1px;
          background: #c2cdd9;
          position: absolute;
          right: -8px; }
          @media only screen and (max-width: 47.9375em) {
            .stats__list__item:not(:last-child)::before, .stats__list__item:not(:last-child)::after {
              width: 100%;
              height: 15px;
              background: transparent;
              border-left: 1px solid #c2cdd9;
              border-right: 1px solid #c2cdd9;
              left: 0;
              right: unset; } }
        .stats__list__item:not(:last-child)::before {
          top: 0; }
          @media only screen and (max-width: 47.9375em) {
            .stats__list__item:not(:last-child)::before {
              content: none; } }
        .stats__list__item:not(:last-child)::after {
          bottom: 0; }
          @media only screen and (max-width: 47.9375em) {
            .stats__list__item:not(:last-child)::after {
              bottom: -8px; } }
      .stats__list__item__text {
        font-family: 'Inter';
        display: flex;
        justify-content: center;
        max-width: 16rem;
        width: 100%;
        text-align: center;
        color: #4d5e72; }
      .stats__list__item__num {
        display: flex;
        color: #2d9cdb;
        margin-top: 0.8rem;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on; }
      .stats__list__item__name {
        display: flex;
        justify-content: center;
        max-width: 16rem;
        width: 100%;
        text-align: center;
        color: #2d9cdb;
        margin-top: 0.5rem; }
  .stats--services {
    background: #15212f;
    color: #ffffff;
    padding: 12rem 0 14rem 0; }
    @media only screen and (max-width: 80em) {
      .stats--services {
        padding: 10rem 0 12rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--services {
        padding: 6rem 0 8rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--services .container {
        max-width: unset; } }
    .stats--services .stats__title {
      margin-bottom: 4rem; }
      @media only screen and (max-width: 80em) {
        .stats--services .stats__title {
          margin-bottom: 3rem; } }
      @media only screen and (max-width: 47.9375em) {
        .stats--services .stats__title {
          margin-bottom: 2rem; } }
    .stats--services .stats__text {
      font-family: 'Inter';
      max-width: 56rem;
      width: 100%;
      margin-bottom: 10rem; }
      @media only screen and (max-width: 80em) {
        .stats--services .stats__text {
          margin-bottom: 7rem; } }
    @media only screen and (max-width: 80em) {
      .stats--services .stats__list {
        padding: 0 1.9rem; } }
    @media only screen and (max-width: 80em) {
      .stats--services .stats__list__item:nth-child(1) {
        max-width: 21.8rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--services .stats__list__item:nth-child(1) {
        max-width: none; } }
    @media only screen and (max-width: 80em) {
      .stats--services .stats__list__item:nth-child(2) {
        max-width: 30.9rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--services .stats__list__item:nth-child(2) {
        max-width: none; } }
    @media only screen and (max-width: 80em) {
      .stats--services .stats__list__item:nth-child(3) {
        max-width: 23rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--services .stats__list__item:nth-child(3) {
        max-width: none; } }
    .stats--services .stats__list__item:not(:last-child) {
      border-right: 1px solid #384757; }
      @media only screen and (max-width: 47.9375em) {
        .stats--services .stats__list__item:not(:last-child) {
          border-right: none;
          border-bottom: 1px solid #384757; } }
      .stats--services .stats__list__item:not(:last-child)::before, .stats--services .stats__list__item:not(:last-child)::after {
        background: #384757; }
        @media only screen and (max-width: 47.9375em) {
          .stats--services .stats__list__item:not(:last-child)::before, .stats--services .stats__list__item:not(:last-child)::after {
            background: transparent;
            border-left: 1px solid #384757;
            border-right: 1px solid #384757; } }
    .stats--services .stats__list__item__num {
      color: #ffffff; }
    .stats--services .stats__list__item__text {
      color: #c2cdd9; }
    .stats--services .stats__list__item__name {
      color: #ffffff; }
  .stats--about {
    padding: 12rem 0 14rem 0; }
    @media only screen and (max-width: 80em) {
      .stats--about {
        padding: 10rem 0 12rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--about {
        padding: 6rem 0 8rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--about .container {
        max-width: unset; } }
    .stats--about .stats__title {
      margin-bottom: 4rem; }
      @media only screen and (max-width: 80em) {
        .stats--about .stats__title {
          margin-bottom: 3rem; } }
      @media only screen and (max-width: 47.9375em) {
        .stats--about .stats__title {
          margin-bottom: 2rem; } }
    .stats--about .stats__text {
      font-family: 'Inter';
      max-width: 56rem;
      width: 100%;
      margin-bottom: 10rem; }
      @media only screen and (max-width: 80em) {
        .stats--about .stats__text {
          margin-bottom: 7rem; } }
      @media only screen and (max-width: 47.9375em) {
        .stats--about .stats__text {
          max-width: none; } }
    @media only screen and (max-width: 80em) {
      .stats--about .stats__list {
        padding: 0; } }
    @media only screen and (max-width: 80em) {
      .stats--about .stats__list__item:nth-child(1) {
        max-width: 21.8rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--about .stats__list__item:nth-child(1) {
        max-width: none; } }
    @media only screen and (max-width: 80em) {
      .stats--about .stats__list__item:nth-child(2) {
        max-width: 30.9rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--about .stats__list__item:nth-child(2) {
        max-width: none; } }
    @media only screen and (max-width: 80em) {
      .stats--about .stats__list__item:nth-child(3) {
        max-width: 23rem; } }
    @media only screen and (max-width: 47.9375em) {
      .stats--about .stats__list__item:nth-child(3) {
        max-width: none; } }

.promo {
  display: flex;
  padding: 12rem 0 14rem 0;
  background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
  color: #ffffff; }
  @media only screen and (max-width: 80em) {
    .promo {
      padding: 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .promo {
      padding: 6rem 0 9rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .promo .container {
      max-width: unset; } }
  .promo__inner {
    display: flex;
    justify-content: flex-end; }
    .promo__inner h4 {
      max-width: 69rem;
      width: 100%;
      margin-right: 7rem; }
      @media only screen and (max-width: 80em) {
        .promo__inner h4 {
          max-width: 41rem;
          margin-right: 10.6rem; } }
      @media only screen and (max-width: 47.9375em) {
        .promo__inner h4 {
          max-width: none;
          margin-right: 2rem;
          font-size: 2.4rem;
          line-height: 2.9rem; } }
  .promo--large {
    padding: 10rem 0 12rem 0; }
    @media only screen and (max-width: 47.9375em) {
      .promo--large {
        padding: 6rem 0 8rem 0; } }
    .promo--large h4 {
      max-width: 76rem;
      margin-right: 0; }
      @media only screen and (max-width: 80em) {
        .promo--large h4 {
          max-width: 41rem;
          margin-right: 10.6rem; } }
      @media only screen and (max-width: 47.9375em) {
        .promo--large h4 {
          max-width: none;
          margin-right: 0; } }

.projects {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 12rem 0;
  overflow: hidden; }
  @media only screen and (max-width: 80em) {
    .projects {
      padding: 10rem 0 5rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .projects {
      padding: 6rem 0 3rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .projects .container {
      max-width: unset; } }
  .projects__inner {
    display: flex;
    flex-direction: column; }
  .projects__title {
    max-width: 36rem;
    width: 100%; }
    @media only screen and (max-width: 80em) {
      .projects__title {
        font-size: 4.8rem;
        line-height: 5.8rem;
        max-width: none; } }
    @media only screen and (max-width: 47.9375em) {
      .projects__title {
        font-size: 3.6rem;
        line-height: 4.3rem; } }
  .projects__slider {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 42rem;
    margin-top: -9.6rem;
    position: relative;
    user-select: none; }
    .projects__slider::before {
      content: '';
      display: flex;
      width: 100%;
      height: 1px;
      background: #c2cdd9;
      position: absolute;
      bottom: 17.9rem; }
    .projects__slider::after {
      content: '';
      display: flex;
      width: 100%;
      height: 15px;
      background: transparent;
      border-left: 1px solid #c2cdd9;
      border-right: 1px solid #c2cdd9;
      position: absolute;
      bottom: 17.2rem; }
    @media only screen and (max-width: 80em) {
      .projects__slider {
        height: inherit;
        margin-top: 4rem; }
        .projects__slider::before, .projects__slider::after {
          content: none; } }
    .projects__slider__wrapper {
      display: flex;
      align-items: flex-end; }
      @media only screen and (max-width: 80em) {
        .projects__slider__wrapper {
          overflow-x: auto;
          overflow-y: hidden;
          padding-bottom: 5rem; } }
      @media only screen and (max-width: 47.9375em) {
        .projects__slider__wrapper {
          padding-bottom: 3rem; } }
    .projects__slider .swiper-control {
      display: flex;
      position: absolute;
      top: calc(9.6rem + 4rem);
      left: 0; }
      @media only screen and (max-width: 80em) {
        .projects__slider .swiper-control {
          display: none; } }
    .projects__slider__slide {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-left: 6.1rem;
      position: relative;
      max-width: 8rem;
      width: 100%;
      height: 12rem; }
      @media only screen and (max-width: 80em) {
        .projects__slider__slide {
          max-width: unset;
          width: inherit;
          height: inherit; } }
      @media only screen and (max-width: 80em) {
        .projects__slider__slide {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 4rem; } }
      .projects__slider__slide:first-child {
        margin-left: 0; }
      .projects__slider__slide:nth-child(4) {
        max-width: unset;
        width: 28rem;
        height: 42rem;
        margin-left: 7.6rem; }
        @media only screen and (max-width: 80em) {
          .projects__slider__slide:nth-child(4) {
            max-width: unset;
            width: inherit;
            height: inherit;
            margin-left: 4rem; } }
        .projects__slider__slide:nth-child(4) + .projects__slider__slide {
          margin-left: 8rem; }
          @media only screen and (max-width: 80em) {
            .projects__slider__slide:nth-child(4) + .projects__slider__slide {
              margin-left: 4rem; } }
      .projects__slider__slide.hide .projects__slider__slide__info {
        opacity: 0;
        visibility: hidden; }
      .projects__slider__slide.active.animated .projects__slider__slide__info {
        z-index: 5; }
      .projects__slider__slide.active .projects__slider__slide__image {
        max-width: 28rem;
        width: 100%;
        height: 42rem;
        transition: all 0.5s ease; }
      .projects__slider__slide.active .projects__slider__slide__info {
        animation: visibleSliderText 1s;
        animation-delay: 0.3s;
        z-index: -1; }
        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          .projects__slider__slide.active .projects__slider__slide__info {
            opacity: 1;
            visibility: visible;
            transition-delay: 0.3s; } }
      .projects__slider__slide__image {
        max-width: 8rem;
        width: 100%;
        height: 12rem; }
        @media only screen and (max-width: 80em) {
          .projects__slider__slide__image {
            max-width: 23.8rem;
            height: 35.7rem; } }
        @media only screen and (max-width: 47.9375em) {
          .projects__slider__slide__image {
            max-width: 22rem;
            height: 33rem; } }
      .projects__slider__slide__info {
        display: flex;
        flex-direction: column;
        width: 36rem;
        position: absolute;
        top: 0;
        left: calc(100% + 8rem); }
        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          .projects__slider__slide__info {
            opacity: 0;
            visibility: hidden; } }
        @media only screen and (max-width: 80em) {
          .projects__slider__slide__info {
            width: 23.8rem;
            position: unset;
            margin-top: 2rem; } }
  @media only screen and (max-width: 80em) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .projects__slider__slide__info {
      opacity: 1;
      visibility: visible; } }
        @media only screen and (max-width: 47.9375em) {
          .projects__slider__slide__info {
            width: 22rem; } }
        .projects__slider__slide__info__num {
          font-family: 'Inter';
          color: #4d5e72;
          margin-bottom: 1rem; }
        .projects__slider__slide__info__title {
          max-width: 35.5rem;
          width: 100%;
          margin-bottom: 3rem; }
          @media only screen and (max-width: 80em) {
            .projects__slider__slide__info__title {
              margin-bottom: 2rem; } }
          @media only screen and (max-width: 47.9375em) {
            .projects__slider__slide__info__title {
              font-size: 2rem;
              line-height: 2.4rem;
              max-width: 20rem; } }

.videoplayer {
  display: flex;
  flex-direction: column;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/images/preview.webp");
  color: #ffffff;
  padding: 6rem 0 15.6rem 0; }
  @media only screen and (max-width: 80em) {
    .videoplayer {
      padding: 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .videoplayer {
      padding: 8.3rem 0 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .videoplayer .container {
      max-width: unset; } }
  .videoplayer__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 76rem;
    width: 100%;
    margin-left: auto; }
    @media only screen and (max-width: 80em) {
      .videoplayer__inner {
        flex-wrap: nowrap;
        flex-direction: column;
        max-width: unset;
        position: relative; } }
    @media only screen and (max-width: 47.9375em) {
      .videoplayer__inner {
        align-items: flex-start; } }
  .videoplayer__title {
    margin-bottom: 8rem; }
    @media only screen and (max-width: 80em) {
      .videoplayer__title {
        max-width: 51.6rem;
        width: 100%;
        margin-bottom: 4rem; } }
    @media only screen and (max-width: 47.9375em) {
      .videoplayer__title {
        max-width: unset;
        order: 2; } }
  .videoplayer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.6rem;
    height: 13.6rem;
    margin: 0 25.1rem 13rem 1.3rem;
    position: relative; }
    @media only screen and (max-width: 80em) {
      .videoplayer__btn {
        width: 10.1rem;
        height: 10.1rem;
        margin: 0;
        position: absolute;
        top: 1rem;
        left: 5rem; } }
    @media only screen and (max-width: 47.9375em) {
      .videoplayer__btn {
        width: 13.6rem;
        height: 13.6rem;
        position: unset;
        order: 1;
        margin-left: 1.6rem;
        margin-bottom: 2.3rem; } }
    .videoplayer__btn:hover::before {
      opacity: 1; }
    .videoplayer__btn:hover::after {
      opacity: 0; }
    .videoplayer__btn::before, .videoplayer__btn::after {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      opacity: 1;
      transition: all 0.3s ease; }
      @media only screen and (max-width: 80em) {
        .videoplayer__btn::before, .videoplayer__btn::after {
          width: 10.1rem;
          height: 10.1rem; } }
      @media only screen and (max-width: 47.9375em) {
        .videoplayer__btn::before, .videoplayer__btn::after {
          width: 13.6rem;
          height: 13.6rem; } }
    .videoplayer__btn::before {
      content: url("/assets/images/play-btn-hover.svg");
      opacity: 0;
      transition: all 0.3s ease; }
      @media only screen and (max-width: 80em) {
        .videoplayer__btn::before {
          content: url("/assets/images/play-btn-tablet-hover.svg"); } }
      @media only screen and (max-width: 47.9375em) {
        .videoplayer__btn::before {
          content: url("/assets/images/play-btn-hover.svg"); } }
    .videoplayer__btn::after {
      content: url("/assets/images/play-btn.svg");
      opacity: 1; }
      @media only screen and (max-width: 80em) {
        .videoplayer__btn::after {
          content: url("/assets/images/play-btn-tablet.svg"); } }
      @media only screen and (max-width: 47.9375em) {
        .videoplayer__btn::after {
          content: url("/assets/images/play-btn.svg"); } }
    .videoplayer__btn__pulse {
      position: absolute;
      width: 27rem;
      height: 27rem;
      border-radius: 50%;
      border: 1px solid #ffffff;
      animation: scale-down-center 2s 0.3s infinite;
      z-index: 100; }
      @media only screen and (max-width: 80em) {
        .videoplayer__btn__pulse {
          width: 20rem;
          height: 20rem; } }
      .videoplayer__btn__pulse::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 27rem;
        height: 27rem;
        border-radius: 50%;
        border: 1px solid #ffffff;
        animation: scale-down-center-third 2s 0.3s infinite; }
        @media only screen and (max-width: 80em) {
          .videoplayer__btn__pulse::before {
            width: 20rem;
            height: 20rem; } }
      .videoplayer__btn__pulse::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 27rem;
        height: 27rem;
        border-radius: 50%;
        border: 1px solid #ffffff;
        animation: scale-down-center-sec 2s 0.3s infinite; }
        @media only screen and (max-width: 80em) {
          .videoplayer__btn__pulse::after {
            width: 20rem;
            height: 20rem; } }
  .videoplayer__info {
    display: flex;
    flex-direction: column;
    max-width: 36rem;
    width: 100%; }
    @media only screen and (max-width: 80em) {
      .videoplayer__info {
        max-width: 45rem;
        margin-right: 6.6rem; } }
    @media only screen and (max-width: 47.9375em) {
      .videoplayer__info {
        order: 3; } }
    .videoplayer__info__text {
      font-family: 'Inter'; }
    .videoplayer__info .btn-link {
      margin-top: 8rem; }
      @media only screen and (max-width: 80em) {
        .videoplayer__info .btn-link {
          margin-top: 6.3rem; } }
      @media only screen and (max-width: 47.9375em) {
        .videoplayer__info .btn-link {
          margin-top: 4rem; } }

.team {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 12rem 0 10rem 0; }
  @media only screen and (max-width: 80em) {
    .team {
      padding: 10rem 0 7rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .team {
      padding: 6rem 0 5rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .team .container {
      max-width: unset; } }
  @media only screen and (max-width: 41.875em) {
    .team .container {
      max-width: unset; } }
  .team__inner {
    display: flex;
    flex-direction: column; }
  .team__title {
    max-width: 76rem;
    width: 100%;
    margin-bottom: 6rem; }
    @media only screen and (max-width: 80em) {
      .team__title {
        margin-bottom: 4rem; } }
    @media only screen and (max-width: 41.875em) {
      .team__title {
        margin-bottom: 0; } }
  .team__list {
    display: flex; }
    @media only screen and (max-width: 80em) {
      .team__list {
        flex-wrap: wrap; } }
    @media only screen and (max-width: 47.9375em) {
      .team__list .team__list__item {
        margin-right: 4rem; }
      .team__list .btn-link {
        margin-top: 4rem; } }
    @media only screen and (max-width: 41.875em) {
      .team__list {
        max-width: 38rem;
        margin: 0 auto; }
        .team__list .team__list__item {
          margin-top: 4rem; }
          .team__list .team__list__item:nth-child(even) {
            margin-right: 0; } }
    .team__list__item {
      display: flex;
      flex-direction: column;
      max-width: 26rem;
      width: 100%;
      margin-right: 4rem; }
      @media only screen and (max-width: 80em) {
        .team__list__item {
          max-width: 23.8rem;
          margin-right: 0;
          margin-left: 4rem; }
          .team__list__item:first-child, .team__list__item:last-child {
            margin-left: 0; } }
      @media only screen and (max-width: 52.125em) {
        .team__list__item {
          margin-left: auto; } }
      @media only screen and (max-width: 47.9375em) {
        .team__list__item {
          max-width: 17rem;
          margin: 0; } }
      @media only screen and (max-width: 26.1875em) {
        .team__list__item {
          max-width: calc(50% - 2rem); } }
      .team__list__item:nth-last-child(2) {
        display: none; }
        @media only screen and (max-width: 41.875em) {
          .team__list__item:nth-last-child(2) {
            display: flex; } }
      .team__list__item:last-child {
        margin-right: 0; }
      .team__list__item__image {
        display: flex;
        max-width: 26rem;
        width: 100%;
        max-height: 26rem;
        height: 100%;
        margin-bottom: 2rem; }
        @media only screen and (max-width: 80em) {
          .team__list__item__image {
            max-width: 23.8rem;
            max-height: 23.8rem;
            margin-bottom: 1rem; } }
        @media only screen and (max-width: 47.9375em) {
          .team__list__item__image {
            max-width: 17rem;
            max-height: 17rem; } }
      .team__list__item__text {
        font-family: 'Inter';
        color: #4d5e72; }
      @media only screen and (max-width: 47.9375em) {
        .team__list__item__name {
          font-size: 2rem;
          line-height: 2.4rem; } }

.partners {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 10rem 0 12rem 0; }
  @media only screen and (max-width: 80em) {
    .partners {
      padding: 7rem 0 10rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .partners {
      padding: 5rem 0 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .partners .container {
      max-width: unset; } }
  .partners__inner {
    display: flex;
    flex-direction: column; }
  .partners__title {
    margin-bottom: 10rem; }
    @media only screen and (max-width: 80em) {
      .partners__title {
        margin-bottom: 7rem; } }
    @media only screen and (max-width: 47.9375em) {
      .partners__title {
        margin-bottom: 4rem; } }
  .partners__list {
    display: grid;
    grid-template-columns: 11.4rem 25.6rem 17.2rem 17.7rem;
    grid-template-rows: 5.6rem 7.1rem 6.1rem 6.6rem;
    grid-row-gap: 7.9rem;
    justify-content: space-between; }
    @media only screen and (max-width: 80em) {
      .partners__list {
        grid-template-columns: 9rem 20.2rem 13.6rem 14rem;
        grid-template-rows: 4.5rem 5.6rem 4.9rem 5.2rem;
        grid-row-gap: 4.3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .partners__list {
        display: grid;
        grid-template-columns: 10.8rem 16.1rem;
        grid-template-rows: 3.5rem repeat(2, 3.7rem) 4.1rem 3rem 4.5rem 3.4rem 2.4rem;
        grid-column-gap: 7.9rem;
        grid-row-gap: 3.8rem;
        margin-right: 3.2rem;
        max-width: 42rem;
        width: 100%;
        margin: 0 auto; } }
    .partners__list img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 7.1rem; }
      @media only screen and (max-width: 80em) {
        .partners__list img {
          max-height: 5.6rem; } }
      @media only screen and (max-width: 47.9375em) {
        .partners__list img {
          max-height: 4.5rem; } }
    .partners__list__link {
      display: flex;
      text-decoration: none; }
    .partners__list__item {
      display: grid;
      justify-self: center;
      align-self: center; }

.consultation {
  display: flex;
  background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
  color: #ffffff;
  padding: 8rem 0; }
  @media only screen and (max-width: 80em) {
    .consultation {
      padding: 6rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .consultation {
      padding: 6rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .consultation .container {
      max-width: unset; } }
  .consultation__inner {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 47.9375em) {
      .consultation__inner {
        flex-direction: column;
        flex-wrap: nowrap; } }
  .consultation__title {
    margin-bottom: 6rem; }
    @media only screen and (max-width: 80em) {
      .consultation__title {
        margin-bottom: 4rem; } }
  .consultation__link {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 58rem;
    width: 100%;
    height: 14rem;
    text-decoration: none;
    position: relative; }
    @media only screen and (max-width: 80em) {
      .consultation__link {
        max-width: 39.7rem;
        height: 10rem; } }
    @media only screen and (max-width: 52.125em) {
      .consultation__link {
        max-width: 50%; } }
    @media only screen and (max-width: 47.9375em) {
      .consultation__link {
        max-width: none;
        height: 7rem; } }
    .consultation__link--primary {
      background: #15212f;
      color: #ffffff; }
    .consultation__link--secondary {
      background: #ffffff;
      color: #15212f;
      transition: all 0.3s ease; }
    .consultation__link::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
      opacity: 0;
      transition: all 0.3s ease; }
    .consultation__link:hover::after {
      opacity: 1; }
    .consultation__link:hover.consultation__link--secondary {
      color: #ffffff; }
      .consultation__link:hover.consultation__link--secondary svg path {
        stroke: #ffffff; }
    .consultation__link__text {
      font-family: 'Inter';
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2.1rem;
      text-transform: uppercase;
      margin-right: 1rem;
      z-index: 2; }
      @media only screen and (max-width: 47.9375em) {
        .consultation__link__text {
          font-size: 1.4rem;
          line-height: 1.8rem; } }
    .consultation__link svg {
      z-index: 2; }
      .consultation__link svg path {
        transition: all 0.3s ease; }

.search {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 9rem 0 12rem 0;
  margin-top: 10rem; }
  @media only screen and (max-width: 80em) {
    .search {
      padding: 7rem 0 12rem 0;
      margin-top: 8rem; } }
  @media only screen and (max-width: 47.9375em) {
    .search {
      padding: 5rem 0 8rem 0;
      margin-top: 6.4rem; } }
  @media only screen and (max-width: 47.9375em) {
    .search .container {
      max-width: none; } }
  .search__inner {
    display: flex;
    flex-direction: column; }
  .search__title {
    color: #2d9cdb;
    margin-bottom: 4rem; }
    @media only screen and (max-width: 80em) {
      .search__title {
        font-size: 7rem;
        line-height: 8.4rem;
        margin-bottom: 3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .search__title {
        font-size: 3.6rem;
        line-height: 4.3rem; } }
  .search__field {
    display: flex;
    max-width: 76rem;
    width: 100%;
    position: relative; }
    .search__field__input {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.9rem;
      border: none;
      background: #ffffff;
      border: 2px solid #c2cdd9;
      border-right: none;
      width: calc(100% - 7rem);
      height: 7rem;
      padding: 0 3rem;
      cursor: default; }
      .search__field__input::placeholder {
        color: #788594; }
      .search__field__input:focus {
        cursor: text; }
    .search__field__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 7rem;
      height: 7rem;
      background: #2d9cdb;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 2; }
      .search__field__btn::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        opacity: 0;
        transition: all 0.3s ease; }
      .search__field__btn:hover::after {
        opacity: 1; }
  .search__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 36rem);
    gap: 7rem 4rem;
    margin-top: 9rem; }
    @media only screen and (max-width: 80em) {
      .search__list {
        grid-template-columns: repeat(auto-fill, 37.7rem);
        margin-top: 7rem; } }
    @media only screen and (max-width: 52.125em) {
      .search__list {
        grid-template-columns: repeat(auto-fill, 34.4rem); } }
    @media only screen and (max-width: 47.9375em) {
      .search__list {
        grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
        gap: 5rem 2rem;
        margin-top: 5rem; } }
    .search__list__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 36rem;
      width: 100%; }
      @media only screen and (max-width: 80em) {
        .search__list__item {
          max-width: 37.7rem; } }
      @media only screen and (max-width: 52.125em) {
        .search__list__item {
          max-width: 34.4rem; } }
      @media only screen and (max-width: 47.9375em) {
        .search__list__item {
          max-width: none; } }
      .search__list__item__title {
        margin-bottom: 3rem; }
      .search__list__item__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 3rem; }
  .search__pagination {
    display: flex;
    align-items: center;
    margin-top: 7rem; }
    .search__pagination__item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e1e7ed;
      width: 4.4rem;
      height: 4.4rem;
      margin-left: 2rem;
      transition: all 0.3s ease; }
      @media only screen and (max-width: 47.9375em) {
        .search__pagination__item {
          margin-left: 1rem; }
          .search__pagination__item:nth-child(5), .search__pagination__item:nth-child(6) {
            display: none; } }
      .search__pagination__item:first-child {
        margin-left: 0; }
        .search__pagination__item:first-child + .search__pagination__item {
          margin-left: 6rem; }
          @media only screen and (max-width: 47.9375em) {
            .search__pagination__item:first-child + .search__pagination__item {
              margin-left: 2rem; } }
      .search__pagination__item:last-child {
        margin-left: 6rem; }
        @media only screen and (max-width: 47.9375em) {
          .search__pagination__item:last-child {
            margin-left: 2rem; } }
      .search__pagination__item:hover {
        background: #4d5e72; }
        .search__pagination__item:hover .search__pagination__item__link {
          color: #ffffff; }
          .search__pagination__item:hover .search__pagination__item__link path {
            fill: #ffffff; }
      .search__pagination__item--secondary {
        font-family: 'Inter';
        font-weight: 800;
        background: transparent;
        margin-left: 0; }
        .search__pagination__item--secondary:hover {
          background: transparent; }
        .search__pagination__item--secondary + .search__pagination__item {
          margin-left: 0; }
      .search__pagination__item__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-family: 'Inter';
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 2.1rem;
        text-transform: uppercase;
        text-decoration: none;
        color: #15212f;
        transition: all 0.3s ease; }
        .search__pagination__item__link__content {
          display: flex; }
          .search__pagination__item__link__content path {
            fill: #15212f;
            transition: all 0.3s ease; }

.cta {
  display: flex;
  background: linear-gradient(100.3deg, #2d9cdb 0%, #32dbb2 65.2%, #61dd84 121.67%);
  color: #ffffff;
  padding: 6rem 0 8rem 0; }
  .cta__inner {
    display: flex;
    align-items: center;
    flex-direction: column; }
  @media only screen and (max-width: 47.9375em) {
    .cta .container {
      max-width: unset; } }
  .cta__title {
    text-align: center;
    margin-bottom: 2rem; }
  .cta__text {
    font-family: 'Inter';
    text-align: center;
    max-width: 62rem;
    width: 100%;
    margin-bottom: 5rem; }
  .cta__link {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 36rem;
    width: 100%;
    height: 7rem;
    background: #15212f;
    text-decoration: none;
    position: relative; }
    .cta__link::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
      opacity: 0;
      transition: all 0.3s ease; }
    .cta__link:hover::after {
      opacity: 1; }
    .cta__link__text {
      font-family: 'Inter';
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2.1rem;
      text-transform: uppercase;
      margin-right: 1rem;
      z-index: 2;
      color: #ffffff; }
      @media only screen and (max-width: 47.9375em) {
        .cta__link__text {
          font-size: 1.4rem;
          line-height: 1.8rem; } }
    .cta__link svg {
      z-index: 2; }
      .cta__link svg path {
        stroke: #ffffff;
        transition: all 0.3s ease; }

.centered-slider {
  display: flex;
  flex-direction: column;
  background: #e1e7ed;
  color: #15212f;
  padding: 12rem 0 14rem 0;
  overflow: hidden; }
  @media only screen and (max-width: 80em) {
    .centered-slider {
      padding: 10rem 0 12rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .centered-slider {
      padding: 6rem 0 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .centered-slider .container {
      max-width: unset; } }
  .centered-slider__inner {
    display: flex;
    flex-direction: column;
    position: relative; }
    .centered-slider__inner .swiper-control {
      position: absolute;
      top: 2rem;
      left: unset;
      right: 0;
      z-index: 10; }
      @media only screen and (max-width: 80em) {
        .centered-slider__inner .swiper-control {
          display: none; } }
      .centered-slider__inner .swiper-control__btn {
        background: #ffffff; }
        .centered-slider__inner .swiper-control__btn:hover {
          background: #4d5e72; }
  .centered-slider__title {
    max-width: 100rem;
    width: 100%;
    margin-bottom: 4rem;
    user-select: none; }
    @media only screen and (max-width: 80em) {
      .centered-slider__title {
        max-width: unset;
        margin-bottom: 3rem; } }
  .centered-slider__text {
    font-family: 'Inter';
    max-width: 56rem;
    width: 100%;
    margin-bottom: 5rem;
    user-select: none; }
    @media only screen and (max-width: 80em) {
      .centered-slider__text {
        margin-bottom: 4rem; } }
  .centered-slider .swiper {
    width: 100%;
    height: 100%; }
    .centered-slider .swiper-wrapper {
      align-items: center; }
    .centered-slider .swiper-slide {
      width: 76rem !important;
      height: 30rem; }
      @media only screen and (max-width: 80em) {
        .centered-slider .swiper-slide {
          width: 51.6rem !important;
          height: 25rem; } }
      @media only screen and (max-width: 47.9375em) {
        .centered-slider .swiper-slide {
          width: 34rem !important;
          height: 17rem; } }
      .centered-slider .swiper-slide-active {
        display: flex;
        align-items: center;
        height: 42.8rem; }
        @media only screen and (max-width: 80em) {
          .centered-slider .swiper-slide-active {
            height: 29rem; } }
        @media only screen and (max-width: 47.9375em) {
          .centered-slider .swiper-slide-active {
            height: 19.1rem; } }
        .centered-slider .swiper-slide-active img {
          width: 76rem !important;
          height: 42.8rem;
          transition: height 0.3s ease; }
          @media only screen and (max-width: 80em) {
            .centered-slider .swiper-slide-active img {
              width: 51.6rem !important;
              height: 29rem; } }
          @media only screen and (max-width: 47.9375em) {
            .centered-slider .swiper-slide-active img {
              width: 34rem !important;
              height: 19.1rem; } }
  .centered-slider__slide {
    display: flex; }
    .centered-slider__slide img {
      width: 100%;
      height: 30rem;
      object-fit: cover; }
      @media only screen and (max-width: 80em) {
        .centered-slider__slide img {
          height: 25rem; } }
      @media only screen and (max-width: 47.9375em) {
        .centered-slider__slide img {
          height: 17rem; } }

.pagination-slider {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 12rem 0 14rem 0;
  overflow-x: hidden; }
  @media only screen and (max-width: 80em) {
    .pagination-slider {
      padding: 10rem 0 12rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .pagination-slider {
      padding: 8rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .pagination-slider .container {
      max-width: unset; } }
  .pagination-slider__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .pagination-slider .swiper {
    margin-top: 6rem; }
    @media only screen and (max-width: 80em) {
      .pagination-slider .swiper {
        margin-top: 3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .pagination-slider .swiper {
        margin-top: 2rem; } }
    .pagination-slider .swiper-pagination {
      display: flex;
      position: unset;
      margin-top: 4rem;
      border-top: 2px solid #c2cdd9; }
      @media only screen and (max-width: 80em) {
        .pagination-slider .swiper-pagination {
          overflow-x: scroll;
          padding-bottom: 2rem; } }
      .pagination-slider .swiper-pagination-bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        font-weight: 700;
        line-height: 3.6rem;
        background: #ffffff;
        width: 16.8rem;
        height: 5.6rem;
        border-radius: 0;
        padding-top: 1.8rem;
        margin: 0 !important;
        opacity: 1 !important;
        transition: all 0.3s ease;
        outline: none;
        position: relative;
        user-select: none; }
        @media only screen and (max-width: 80em) {
          .pagination-slider .swiper-pagination-bullet {
            font-size: 2rem;
            line-height: 2.4rem;
            min-width: 16.8rem;
            height: 4.6rem;
            padding-top: 2.2rem; } }
        @media only screen and (max-width: 47.9375em) {
          .pagination-slider .swiper-pagination-bullet {
            min-width: 13.4rem;
            height: 5.2rem;
            padding-top: 2.8rem; } }
        .pagination-slider .swiper-pagination-bullet span {
          color: #c2cdd9;
          background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
          -webkit-background-clip: text;
          background-clip: text;
          transition: all 0.3s ease; }
        .pagination-slider .swiper-pagination-bullet-active span {
          color: #2d9cdb; }
        .pagination-slider .swiper-pagination-bullet:hover span {
          color: transparent; }
        .pagination-slider .swiper-pagination-bullet:last-child::after {
          content: none; }
        .pagination-slider .swiper-pagination-bullet::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
          height: 2.8rem;
          background: #c2cdd9; }
    .pagination-slider .swiper-control {
      margin-left: auto; }
    .pagination-slider .swiper-slide .pagination-slider__slide {
      opacity: 0; }
    .pagination-slider .swiper-slide-active .pagination-slider__slide {
      opacity: 1;
      transition: all 0.5s ease; }
  .pagination-slider__slide {
    display: flex; }
    @media only screen and (max-width: 47.9375em) {
      .pagination-slider__slide {
        flex-direction: column; } }
    .pagination-slider__slide__image {
      max-width: 56rem;
      width: 100%;
      height: 40rem;
      margin-right: 4rem; }
      @media only screen and (max-width: 80em) {
        .pagination-slider__slide__image {
          max-width: 37.7rem;
          object-fit: cover; } }
      @media only screen and (max-width: 47.9375em) {
        .pagination-slider__slide__image {
          max-width: 100%;
          height: 27.2rem;
          margin-right: 0;
          margin-bottom: 4rem; } }
    .pagination-slider__slide__info {
      display: flex;
      flex-direction: column; }
      .pagination-slider__slide__info__title {
        margin-bottom: 3rem; }
        @media only screen and (max-width: 80em) {
          .pagination-slider__slide__info__title {
            margin-bottom: 2rem; } }
        @media only screen and (max-width: 47.9375em) {
          .pagination-slider__slide__info__title {
            font-size: 2.4rem;
            line-height: 2.9rem;
            margin-bottom: 3rem; } }
      .pagination-slider__slide__info__text {
        font-family: 'Inter';
        margin-bottom: 3rem; }
      .pagination-slider__slide__info .btn-link {
        margin-top: auto; }

.footer {
  display: flex;
  background: #15212f;
  color: #ffffff;
  padding: 6rem 0;
  margin-top: auto; }
  @media only screen and (max-width: 80em) {
    .footer {
      padding: 4rem 0; } }
  @media only screen and (max-width: 47.9375em) {
    .footer .container {
      max-width: unset; } }
  .footer__inner {
    display: flex;
    flex-wrap: wrap; }
  .footer__logo {
    display: flex;
    align-items: center;
    text-decoration: none; }
    @media only screen and (max-width: 47.9375em) {
      .footer__logo svg {
        width: 15.2rem;
        height: 2.8rem; } }
  .footer__navigation {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 2.3rem; }
    @media only screen and (max-width: 80em) {
      .footer__navigation {
        margin-right: 1.4rem; } }
    @media only screen and (max-width: 52.125em) {
      .footer__navigation {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 2.5rem;
        margin-left: 0;
        margin-right: 0; }
        .footer__navigation .footer__navigation__item {
          margin: 1.5rem 0;
          margin-right: 5rem; }
          .footer__navigation .footer__navigation__item:last-child {
            margin-right: 0; } }
    @media only screen and (max-width: 31.875em) {
      .footer__navigation {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 4rem;
        grid-row-gap: 3rem;
        max-width: 42rem;
        margin-top: 4rem; }
        .footer__navigation .footer__navigation__item {
          margin: 0; } }
    @media only screen and (max-width: 26.1875em) {
      .footer__navigation {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem; } }
    .footer__navigation__item {
      display: flex;
      color: #ffffff;
      margin-right: 5rem; }
      @media only screen and (max-width: 80em) {
        .footer__navigation__item {
          margin-right: 4rem; } }
      @media only screen and (max-width: 52.125em) {
        .footer__navigation__item {
          margin: 0; } }
      .footer__navigation__item:last-child {
        margin-right: 0; }
      .footer__navigation__item.active .footer__navigation__item__link {
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #2d9cdb; }
        .footer__navigation__item.active .footer__navigation__item__link:hover {
          color: transparent; }
      .footer__navigation__item__link {
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: 700;
        color: inherit;
        text-decoration: none;
        transition: all 0.3s ease;
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #ffffff; }
        @media only screen and (max-width: 47.9375em) {
          .footer__navigation__item__link {
            font-size: 2rem;
            line-height: 2.4rem; } }
        .footer__navigation__item__link:hover {
          color: transparent; }
  .footer__info {
    display: flex;
    width: 100%;
    color: #788594;
    font-family: 'Inter';
    padding-top: 6.7rem;
    margin-top: 6.7rem;
    border-top: 1px solid #384757;
    position: relative; }
    @media only screen and (max-width: 80em) {
      .footer__info {
        flex-wrap: wrap;
        padding-top: 4.7rem;
        margin-top: 4.7rem; } }
    @media only screen and (max-width: 52.125em) {
      .footer__info {
        margin-top: 3.2rem; } }
    @media only screen and (max-width: 31.875em) {
      .footer__info {
        margin-top: 4.7rem; } }
    .footer__info::before {
      content: '';
      display: flex;
      align-items: center;
      width: 100%;
      height: 15px;
      position: absolute;
      top: -8px;
      left: 0;
      border-right: 1px solid #384757;
      border-left: 1px solid #384757; }
    .footer__info__copyright {
      max-width: 36rem;
      width: 100%;
      margin-right: 4rem; }
      @media only screen and (max-width: 80em) {
        .footer__info__copyright {
          max-width: 37.7rem; } }
      @media only screen and (max-width: 52.125em) {
        .footer__info__copyright {
          margin-bottom: 2rem; } }
      @media only screen and (max-width: 47.9375em) {
        .footer__info__copyright {
          max-width: unset;
          margin-right: 0; } }
    .footer__info__address {
      max-width: 16rem;
      width: 100%;
      margin-right: 4rem; }
      @media only screen and (max-width: 80em) {
        .footer__info__address {
          max-width: 36rem;
          margin-right: 1.7rem; } }
      @media only screen and (max-width: 47.9375em) {
        .footer__info__address {
          max-width: unset;
          margin-right: 0;
          margin-bottom: 2rem; } }
    .footer__info__text {
      max-width: 32rem;
      width: 100%;
      margin-right: 4rem; }
      @media only screen and (max-width: 80em) {
        .footer__info__text {
          max-width: 37.7rem;
          margin-top: 2rem; } }
      @media only screen and (max-width: 47.9375em) {
        .footer__info__text {
          max-width: unset;
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 2rem; } }
    .footer__info__item {
      display: flex;
      flex-direction: column;
      margin-left: auto; }
      @media only screen and (max-width: 80em) {
        .footer__info__item {
          margin-top: 2rem;
          margin-left: 0; } }
      @media only screen and (max-width: 47.9375em) {
        .footer__info__item {
          flex-direction: row;
          align-items: center;
          margin-top: 0; } }
      .footer__info__item__text {
        margin-bottom: 0.5rem; }
        @media only screen and (max-width: 47.9375em) {
          .footer__info__item__text {
            margin-bottom: 0;
            margin-right: 2.5rem; } }
      .footer__info__item__link {
        display: flex;
        text-decoration: none; }

.header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  color: #ffffff;
  z-index: 100; }
  .header.active .container {
    max-width: 100%; }
    @media only screen and (max-width: 47.9375em) {
      .header.active .container {
        max-width: 100%; } }
  .header.active .header__mobile__navigation {
    transform: translateX(0); }
  .header.active .header__logo svg path:nth-child(-n + 8) {
    fill: #ffffff; }
  .header.active .header__logo svg path:nth-last-child(4) {
    fill: #ffffff; }
  .header .container {
    transition: all 0.3s ease; }
    @media only screen and (max-width: 47.9375em) {
      .header .container {
        max-width: unset; } }
  .header__inner {
    display: flex;
    align-items: center;
    padding: 2.9rem 0; }
    @media only screen and (max-width: 80em) {
      .header__inner {
        padding: 2.5rem 0; } }
    @media only screen and (max-width: 47.9375em) {
      .header__inner {
        padding: 1.8rem 0; } }
  .header__logo {
    display: flex;
    align-items: center;
    height: 4.2rem; }
    @media only screen and (max-width: 80em) {
      .header__logo {
        height: 3rem;
        z-index: 5; } }
    @media only screen and (max-width: 47.9375em) {
      .header__logo {
        height: 2.8rem; } }
    @media only screen and (max-width: 80em) {
      .header__logo svg {
        width: 16.7rem;
        height: 3rem; } }
    @media only screen and (max-width: 47.9375em) {
      .header__logo svg {
        width: 15.2rem;
        height: 2.8rem; } }
    .header__logo svg path {
      transition: all 0.3s ease; }
  .header__navigation {
    display: flex;
    margin-left: auto;
    margin-right: 7.6rem; }
    @media only screen and (max-width: 80em) {
      .header__navigation {
        display: none; } }
    .header__navigation__item {
      display: flex;
      color: #ffffff;
      margin-right: 6rem; }
      .header__navigation__item:last-child {
        margin-right: 0; }
      .header__navigation__item.active .header__navigation__item__link {
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #2d9cdb; }
        .header__navigation__item.active .header__navigation__item__link:hover {
          color: transparent; }
      .header__navigation__item__link {
        font-size: 1.4rem;
        line-height: 2.1rem;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s ease;
        background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        -webkit-background-clip: text;
        background-clip: text;
        color: #ffffff; }
        .header__navigation__item__link:hover {
          color: transparent; }
  .header__search {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative; }
    @media only screen and (max-width: 80em) {
      .header__search {
        display: none; } }
    .header__search.active .header__search__btn {
      background: #2d9cdb; }
    .header__search.active .header__search__input {
      display: flex;
      width: 71.8rem;
      right: 4.2rem;
      opacity: 1;
      visibility: visible; }
    .header__search__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 4.2rem;
      height: 4.2rem;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      z-index: 2; }
      .header__search__btn::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
        opacity: 0;
        transition: all 0.3s ease; }
      .header__search__btn:hover::after {
        opacity: 1; }
    .header__search__input {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.9rem;
      border: none;
      width: 4.2rem;
      height: 4.2rem;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 2rem;
      cursor: default;
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden; }
      .header__search__input::placeholder {
        color: #788594; }
      .header__search__input:focus {
        cursor: text; }
  .header__mobile {
    display: none;
    margin-left: auto; }
    @media only screen and (max-width: 80em) {
      .header__mobile {
        display: flex; } }
    .header__mobile__navigation {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #15212f;
      padding: 18rem 2rem 0 2rem;
      overflow: auto;
      transform: translateX(200%);
      transition: all 0.3s ease; }
      @media only screen and (max-width: 47.9375em) {
        .header__mobile__navigation {
          padding: 10.4rem 2rem 0 2rem; } }
      @media only screen and (max-height: 51.3125em) {
        .header__mobile__navigation {
          padding: 12rem 2rem 0 2rem; } }
      @media only screen and (max-height: 44) {
        .header__mobile__navigation {
          padding: 9rem 2rem 0 2rem; } }
      .header__mobile__navigation__search {
        display: flex; }
        .header__mobile__navigation__search__input {
          display: flex;
          font-family: 'Inter';
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.9rem;
          border: none;
          width: calc(100% - 7rem);
          height: 7rem;
          padding: 0 2rem;
          cursor: default; }
          @media only screen and (max-height: 56.25em) {
            .header__mobile__navigation__search__input {
              height: 5rem;
              width: calc(100% - 5rem); } }
          .header__mobile__navigation__search__input::placeholder {
            color: #788594; }
          .header__mobile__navigation__search__input:focus {
            cursor: text; }
        .header__mobile__navigation__search__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #2d9cdb;
          width: 7rem;
          height: 7rem;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          z-index: 2; }
          @media only screen and (max-height: 56.25em) {
            .header__mobile__navigation__search__btn {
              width: 5rem;
              height: 5rem; } }
          .header__mobile__navigation__search__btn::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            background: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            opacity: 0;
            transition: all 0.3s ease; }
          .header__mobile__navigation__search__btn:hover::after {
            opacity: 1; }
          @media only screen and (max-height: 56.25em) {
            .header__mobile__navigation__search__btn svg {
              height: 2rem; } }
      .header__mobile__navigation__list {
        display: flex;
        flex-direction: column;
        margin-top: 9rem; }
        @media only screen and (max-width: 47.9375em) {
          .header__mobile__navigation__list {
            margin-top: 2.3rem; } }
        @media only screen and (max-height: 51.3125em) {
          .header__mobile__navigation__list {
            margin-top: 2rem; } }
        .header__mobile__navigation__list__item {
          display: flex;
          justify-content: flex-end;
          padding: 3rem 0;
          border-bottom: 1px solid #384757;
          position: relative; }
          @media only screen and (max-width: 47.9375em) {
            .header__mobile__navigation__list__item {
              padding: 2.7rem 0; } }
          @media only screen and (max-height: 51.3125em) {
            .header__mobile__navigation__list__item {
              padding: 2rem 0; } }
          @media only screen and (max-height: 44em) {
            .header__mobile__navigation__list__item {
              padding: 1rem 0; } }
          .header__mobile__navigation__list__item:last-child {
            border-bottom: none; }
            .header__mobile__navigation__list__item:last-child::after {
              content: none; }
          .header__mobile__navigation__list__item::after {
            content: '';
            display: flex;
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 15px;
            border-left: 1px solid #384757;
            border-right: 1px solid #384757; }
          .header__mobile__navigation__list__item.active .header__mobile__navigation__list__item__link {
            background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            -webkit-background-clip: text;
            background-clip: text;
            color: #2d9cdb; }
            .header__mobile__navigation__list__item.active .header__mobile__navigation__list__item__link:hover {
              color: transparent; }
          .header__mobile__navigation__list__item__link {
            font-size: 4rem;
            font-weight: 700;
            line-height: 4.8rem;
            text-decoration: none;
            transition: all 0.3s ease;
            background-image: linear-gradient(102.18deg, #32dbb2 -8.72%, #61dd84 85.25%);
            -webkit-background-clip: text;
            background-clip: text;
            color: #ffffff; }
            @media only screen and (max-width: 47.9375em) {
              .header__mobile__navigation__list__item__link {
                font-size: 2.4rem;
                line-height: 2.9rem; } }
            @media only screen and (max-height: 75em) {
              .header__mobile__navigation__list__item__link {
                font-size: 2.9rem;
                line-height: 3.9rem; } }
            @media only screen and (max-height: 44em) {
              .header__mobile__navigation__list__item__link {
                font-size: 2.4rem;
                line-height: 4rem; } }
            .header__mobile__navigation__list__item__link:hover {
              color: transparent; }
  .header--white {
    background: #ffffff;
    color: #15212f; }
    .header--white .header__navigation__item {
      color: #15212f; }
      .header--white .header__navigation__item__link {
        color: #15212f; }
        .header--white .header__navigation__item__link:hover {
          color: transparent; }
    .header--white .header__logo svg path:nth-child(-n + 8) {
      fill: #15212f; }
    .header--white .header__logo svg path:nth-last-child(4) {
      fill: #15212f; }
    .header--white .header__search.active path {
      stroke: #ffffff; }
    .header--white .header__search__btn:hover path {
      stroke: #ffffff; }
    .header--white .header__search__btn path {
      stroke: #15212f;
      transition: all 0.3s ease; }
    .header--white .header__search__input {
      border: 1px solid #c2cdd9;
      border-right: none; }
    .header--white .btn-hamburger.active > span {
      background: #ffffff; }
      .header--white .btn-hamburger.active > span::before, .header--white .btn-hamburger.active > span::after {
        background: #ffffff; }
    .header--white .btn-hamburger > span {
      background: #15212f; }
      .header--white .btn-hamburger > span::before, .header--white .btn-hamburger > span::after {
        background: #15212f; }

.team-hero {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 9rem 0 5rem 0;
  margin-top: 10rem; }
  @media only screen and (max-width: 80em) {
    .team-hero {
      padding: 7rem 0 3rem 0;
      margin-top: 8rem; } }
  @media only screen and (max-width: 47.9375em) {
    .team-hero {
      padding: 5rem 0 3rem 0;
      margin-top: 6.4rem; } }
  @media only screen and (max-width: 47.9375em) {
    .team-hero .container {
      max-width: unset; } }
  .team-hero__inner {
    display: flex; }
    @media only screen and (max-width: 47.9375em) {
      .team-hero__inner {
        flex-direction: column; } }
  .team-hero__title {
    max-width: 29rem;
    width: 100%;
    margin-top: 0.8rem;
    margin-right: 11rem; }
    @media only screen and (max-width: 80em) {
      .team-hero__title {
        max-width: 16rem;
        margin-top: 2.3rem;
        margin-right: 11.8rem; } }
    @media only screen and (max-width: 47.9375em) {
      .team-hero__title {
        max-width: unset;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 1rem; } }
  .team-hero__info {
    display: flex;
    flex-direction: column; }
    .team-hero__info__title {
      max-width: 76rem;
      width: 100%;
      color: #2d9cdb;
      margin-bottom: 6rem; }
      @media only screen and (max-width: 80em) {
        .team-hero__info__title {
          max-width: 50.5rem;
          margin-bottom: 4rem; } }
      @media only screen and (max-width: 47.9375em) {
        .team-hero__info__title {
          max-width: unset;
          margin-bottom: 3rem; } }
    .team-hero__info__text {
      font-family: 'Inter';
      max-width: 56rem;
      width: 100%; }
      @media only screen and (max-width: 80em) {
        .team-hero__info__text {
          max-width: 50.5rem; } }

.team-list {
  display: flex;
  background: #ffffff;
  color: #15212f;
  padding: 0 0 12rem 0; }
  .team-list__inner {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 52.125em) {
      .team-list__inner {
        justify-content: center; } }
    @media only screen and (max-width: 47.9375em) {
      .team-list__inner {
        justify-content: unset; } }
  .team-list__item {
    display: flex;
    flex-direction: column;
    max-width: 36rem;
    width: 100%;
    position: relative;
    margin-top: 7rem;
    margin-right: 4rem; }
    .team-list__item:nth-child(3n) {
      margin-right: 0; }
    @media only screen and (max-width: 80em) {
      .team-list__item:nth-child(even) {
        margin-right: 0; }
      .team-list__item:nth-child(odd) {
        margin-right: 4rem; } }
    @media only screen and (max-width: 47.9375em) {
      .team-list__item {
        margin-top: 5rem; }
        .team-list__item:nth-child(odd) {
          margin-right: 0; } }
    .team-list__item .team-list__item__popup {
      transform: translateX(0);
      opacity: 1;
      transition: all 0.3s ease; }
    @media only screen and (min-width: 80.0625em) {
      .team-list__item:nth-child(3n-1) .team-list__item__popup {
        transform: translateX(calc(-36rem - 4rem));
        opacity: 1;
        transition: all 0.3s ease; }
      .team-list__item:nth-child(4n-1) .team-list__item__popup {
        transform: translateX(calc(-72rem - 8rem));
        opacity: 1;
        transition: all 0.3s ease; } }
    @media only screen and (max-width: 80em) {
      .team-list__item {
        max-width: 37.7rem; }
        .team-list__item:nth-child(even) .team-list__item__popup {
          transform: translateX(calc(-37.7rem - 4rem));
          opacity: 1;
          transition: all 0.3s ease; } }
    @media only screen and (max-width: 52.125em) {
      .team-list__item {
        max-width: 34.4rem; }
        .team-list__item:nth-child(even) .team-list__item__popup {
          transform: translateX(calc(-100% + 50% - 2rem)); } }
    @media only screen and (max-width: 47.9375em) {
      .team-list__item {
        max-width: 38rem; }
        .team-list__item:nth-child(even) .team-list__item__popup {
          transform: translateX(0);
          opacity: 1;
          transition: all 0.3s ease; }
        .team-list__item.active {
          background: #e1e7ed;
          padding: 2rem; }
          .team-list__item.active .team-list__item__image {
            order: 1;
            margin-bottom: 4rem; }
          .team-list__item.active .team-list__item__name {
            order: 2;
            margin-bottom: 2rem; }
          .team-list__item.active .team-list__item__subname {
            order: 3;
            margin-bottom: 4rem; }
          .team-list__item.active .team-list__item__popup__close {
            top: -11.4rem;
            right: 0; }
          .team-list__item.active .popup-wrapper {
            order: 4; } }
    .team-list__item__image {
      max-width: 36rem;
      width: 100%;
      max-height: 36rem;
      height: 100%;
      margin-bottom: 3rem;
      cursor: pointer; }
      @media only screen and (max-width: 80em) {
        .team-list__item__image {
          max-width: 37.7rem;
          max-height: 37.7rem;
          margin-bottom: 2rem; } }
      @media only screen and (max-width: 47.9375em) {
        .team-list__item__image {
          max-width: 38rem;
          max-height: 38rem; } }
    .team-list__item__name {
      cursor: pointer; }
    .team-list__item__subname {
      font-family: 'Inter';
      color: #4d5e72; }
    .team-list__item .popup-wrapper:not(.active) .team-list__item__popup {
      transform: translateX(-500%);
      opacity: 0;
      transition: all 0.3s ease; }
    @media only screen and (max-width: 47.9375em) {
      .team-list__item .popup-wrapper:not(.active) {
        display: none; } }
    .team-list__item__popup {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      background: #e1e7ed;
      color: #15212f;
      padding: 5rem;
      z-index: 10;
      width: 116rem; }
      @media only screen and (max-width: 80em) {
        .team-list__item__popup {
          width: 79.4rem;
          padding: 4rem; } }
      @media only screen and (max-width: 52.125em) {
        .team-list__item__popup {
          width: 72.8rem; } }
      @media only screen and (max-width: 47.9375em) {
        .team-list__item__popup {
          position: unset;
          top: unset;
          left: unset;
          width: 100%;
          padding: 0; } }
      .team-list__item__popup .popup {
        display: flex; }
      .team-list__item__popup__image {
        display: flex;
        max-width: 35.5rem;
        width: 100%;
        max-height: 35.5rem;
        width: 100%;
        margin-right: 5rem; }
        @media only screen and (max-width: 80em) {
          .team-list__item__popup__image {
            max-width: 37.5rem;
            max-height: 37.5rem;
            margin-right: 4rem; } }
        @media only screen and (max-width: 47.9375em) {
          .team-list__item__popup__image {
            display: none; } }
      .team-list__item__popup__block {
        display: flex;
        flex-direction: column;
        max-width: 55rem;
        width: 100%; }
        @media only screen and (max-width: 80em) {
          .team-list__item__popup__block {
            max-width: 29.9rem; } }
      .team-list__item__popup__name {
        margin-bottom: 2rem; }
        @media only screen and (max-width: 80em) {
          .team-list__item__popup__name {
            max-width: 24rem; } }
        @media only screen and (max-width: 47.9375em) {
          .team-list__item__popup__name {
            display: none; } }
      .team-list__item__popup__subname {
        font-family: 'Inter';
        margin-bottom: 5rem; }
        @media only screen and (max-width: 80em) {
          .team-list__item__popup__subname {
            margin-bottom: 4rem; } }
        @media only screen and (max-width: 47.9375em) {
          .team-list__item__popup__subname {
            display: none; } }
      .team-list__item__popup__text {
        font-family: 'Inter';
        max-width: 50rem;
        width: 100%; }
      .team-list__item__popup__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.4rem;
        height: 4.4rem;
        position: absolute;
        top: 5rem;
        right: 5rem;
        background: #ffffff;
        color: #15212f;
        border-radius: 100%;
        border: none;
        outline: none;
        transition: all 0.3s ease;
        cursor: pointer; }
        @media only screen and (max-width: 80em) {
          .team-list__item__popup__close {
            top: 4rem;
            right: 4rem; } }
        .team-list__item__popup__close:hover {
          background: #4d5e72; }
          .team-list__item__popup__close:hover path {
            stroke: #ffffff; }
        .team-list__item__popup__close path {
          stroke: #15212f;
          transition: all 0.3s ease; }
