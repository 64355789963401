.stats {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 12rem 0;

    @include respond(tab-land) {
        padding: 10rem 0;
    }

    @include respond(phone) {
        padding: 6rem 0 5.5rem 0;
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__list {
        display: flex;

        @include respond(tab-land) {
            padding: 0 5.2rem;
        }

        @include respond(phone) {
            flex-direction: column;
            padding: 0;
        }

        &__item {
            display: flex;
            flex-direction: column;
            padding: 1.4rem 0 1.1rem 0;
            position: relative;

            @include respond(phone) {
                padding: 0;
            }

            &:nth-child(1) {
                align-items: flex-start;

                @include respond(phone) {
                    padding-bottom: 4.1rem;
                }
            }

            &:nth-child(3) {
                align-items: flex-end;

                @include respond(tab-land) {
                    margin-left: auto;
                }

                @include respond(phone) {
                    padding-top: 4.6rem;
                }
            }

            &:nth-child(1),
            &:nth-child(3) {
                max-width: 36.5rem;
                width: 100%;

                @include respond(tab-land) {
                    max-width: 19.8rem;
                }

                @include respond(phone) {
                    max-width: none;
                    align-items: center;
                }

                .stats__list__item__num {
                    max-width: 16rem;
                    width: 100%;
                    justify-content: center;
                }
            }

            &:nth-child(2) {
                align-items: center;
                max-width: 43rem;
                width: 100%;

                @include respond(tab-land) {
                    max-width: 27.7rem;
                }

                @include respond(phone) {
                    max-width: none;
                    padding: 4.7rem 0;
                }
            }

            &:not(:last-child) {
                border-right: 1px solid $color-grey-3;

                @include respond(phone) {
                    border-right: none;
                    border-bottom: 1px solid $color-grey-3;
                }

                &::before,
                &::after {
                    content: '';
                    display: flex;
                    width: 15px;
                    height: 1px;
                    background: $color-grey-3;
                    position: absolute;
                    right: -8px;

                    @include respond(phone) {
                        width: 100%;
                        height: 15px;
                        background: transparent;
                        border-left: 1px solid $color-grey-3;
                        border-right: 1px solid $color-grey-3;
                        left: 0;
                        right: unset;
                    }
                }

                &::before {
                    top: 0;

                    @include respond(phone) {
                        content: none;
                    }
                }

                &::after {
                    bottom: 0;

                    @include respond(phone) {
                        bottom: -8px;
                    }
                }
            }

            &__text {
                font-family: 'Inter';
                display: flex;
                justify-content: center;
                max-width: 16rem;
                width: 100%;
                text-align: center;
                color: $color-grey-1;
            }

            &__num {
                display: flex;
                color: $color-blue;
                margin-top: 0.8rem;
                text-align: center;
                font-feature-settings: 'pnum' on, 'lnum' on;
            }

            &__name {
                display: flex;
                justify-content: center;
                max-width: 16rem;
                width: 100%;
                text-align: center;
                color: $color-blue;
                margin-top: 0.5rem;
            }
        }
    }

    &--services {
        background: $color-black;
        color: $color-white;
        padding: 12rem 0 14rem 0;

        @include respond(tab-land) {
            padding: 10rem 0 12rem 0;
        }

        @include respond(phone) {
            padding: 6rem 0 8rem 0;
        }

        .container {
            @include respond(phone) {
                max-width: unset;
            }
        }

        .stats {
            &__title {
                margin-bottom: 4rem;

                @include respond(tab-land) {
                    margin-bottom: 3rem;
                }

                @include respond(phone) {
                    margin-bottom: 2rem;
                }
            }

            &__text {
                font-family: 'Inter';
                max-width: 56rem;
                width: 100%;
                margin-bottom: 10rem;

                @include respond(tab-land) {
                    margin-bottom: 7rem;
                }
            }

            &__list {
                @include respond(tab-land) {
                    padding: 0 1.9rem;
                }
                &__item {
                    &:nth-child(1) {
                        @include respond(tab-land) {
                            max-width: 21.8rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }
                    &:nth-child(2) {
                        @include respond(tab-land) {
                            max-width: 30.9rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }
                    &:nth-child(3) {
                        @include respond(tab-land) {
                            max-width: 23rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }

                    &:not(:last-child) {
                        border-right: 1px solid $color-grey-2;

                        @include respond(phone) {
                            border-right: none;
                            border-bottom: 1px solid $color-grey-2;
                        }

                        &::before,
                        &::after {
                            background: $color-grey-2;

                            @include respond(phone) {
                                background: transparent;
                                border-left: 1px solid $color-grey-2;
                                border-right: 1px solid $color-grey-2;
                            }
                        }
                    }
                    &__num {
                        color: $color-white;
                    }
                    &__text {
                        color: $color-grey-3;
                    }
                    &__name {
                        color: $color-white;
                    }
                }
            }
        }
    }

    &--about {
        padding: 12rem 0 14rem 0;

        @include respond(tab-land) {
            padding: 10rem 0 12rem 0;
        }

        @include respond(phone) {
            padding: 6rem 0 8rem 0;
        }

        .container {
            @include respond(phone) {
                max-width: unset;
            }
        }

        .stats {
            &__title {
                margin-bottom: 4rem;

                @include respond(tab-land) {
                    margin-bottom: 3rem;
                }

                @include respond(phone) {
                    margin-bottom: 2rem;
                }
            }

            &__text {
                font-family: 'Inter';
                max-width: 56rem;
                width: 100%;
                margin-bottom: 10rem;

                @include respond(tab-land) {
                    margin-bottom: 7rem;
                }

                @include respond(phone) {
                    max-width: none;
                }
            }

            &__list {
                @include respond(tab-land) {
                    padding: 0;
                }
                &__item {
                    &:nth-child(1) {
                        @include respond(tab-land) {
                            max-width: 21.8rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }
                    &:nth-child(2) {
                        @include respond(tab-land) {
                            max-width: 30.9rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }
                    &:nth-child(3) {
                        @include respond(tab-land) {
                            max-width: 23rem;
                        }

                        @include respond(phone) {
                            max-width: none;
                        }
                    }
                }
            }
        }
    }
}
