.partners {
    display: flex;
    background: $color-white;
    color: $color-black;
    padding: 10rem 0 12rem 0;

    @include respond(tab-land) {
        padding: 7rem 0 10rem 0;
    }

    @include respond(phone) {
        padding: 5rem 0 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 10rem;

        @include respond(tab-land) {
            margin-bottom: 7rem;
        }

        @include respond(phone) {
            margin-bottom: 4rem;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: 11.4rem 25.6rem 17.2rem 17.7rem;
        grid-template-rows: 5.6rem 7.1rem 6.1rem 6.6rem;
        grid-row-gap: 7.9rem;
        justify-content: space-between;

        @include respond(tab-land) {
            grid-template-columns: 9rem 20.2rem 13.6rem 14rem;
            grid-template-rows: 4.5rem 5.6rem 4.9rem 5.2rem;
            grid-row-gap: 4.3rem;
        }

        @include respond(phone) {
            display: grid;
            grid-template-columns: 10.8rem 16.1rem;
            grid-template-rows: 3.5rem repeat(2, 3.7rem) 4.1rem 3rem 4.5rem 3.4rem 2.4rem;
            grid-column-gap: 7.9rem;
            grid-row-gap: 3.8rem;
            margin-right: 3.2rem;
            max-width: 42rem;
            width: 100%;
            margin: 0 auto;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            max-height: 7.1rem;

            @include respond(tab-land) {
                max-height: 5.6rem;
            }

            @include respond(phone) {
                max-height: 4.5rem;
            }
        }

        &__link {
            display: flex;
            text-decoration: none;
        }

        &__item {
            display: grid;
            justify-self: center;
            align-self: center;
        }
    }
}
