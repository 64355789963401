.videoplayer {
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/assets/images/preview.webp');
    color: $color-white;
    padding: 6rem 0 15.6rem 0;

    @include respond(tab-land) {
        padding: 10rem 0;
    }

    @include respond(phone) {
        padding: 8.3rem 0 8rem 0;
    }

    .container {
        @include respond(phone) {
            max-width: unset;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        max-width: 76rem;
        width: 100%;
        margin-left: auto;

        @include respond(tab-land) {
            flex-wrap: nowrap;
            flex-direction: column;
            max-width: unset;
            position: relative;
        }

        @include respond(phone) {
            align-items: flex-start;
        }
    }

    &__title {
        margin-bottom: 8rem;

        @include respond(tab-land) {
            max-width: 51.6rem;
            width: 100%;
            margin-bottom: 4rem;
        }

        @include respond(phone) {
            max-width: unset;
            order: 2;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13.6rem;
        height: 13.6rem;
        margin: 0 25.1rem 13rem 1.3rem;
        position: relative;

        @include respond(tab-land) {
            width: 10.1rem;
            height: 10.1rem;
            margin: 0;
            position: absolute;
            top: 1rem;
            left: 5rem;
        }

        @include respond(phone) {
            width: 13.6rem;
            height: 13.6rem;
            position: unset;
            order: 1;
            margin-left: 1.6rem;
            margin-bottom: 2.3rem;
        }

        &:hover {
            &::before {
                opacity: 1;
            }
            &::after {
                opacity: 0;
            }
        }

        &::before,
        &::after {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            opacity: 1;
            transition: all 0.3s ease;

            @include respond(tab-land) {
                width: 10.1rem;
                height: 10.1rem;
            }

            @include respond(phone) {
                width: 13.6rem;
                height: 13.6rem;
            }
        }

        &::before {
            content: url('/assets/images/play-btn-hover.svg');
            opacity: 0;
            transition: all 0.3s ease;

            @include respond(tab-land) {
                content: url('/assets/images/play-btn-tablet-hover.svg');
            }

            @include respond(phone) {
                content: url('/assets/images/play-btn-hover.svg');
            }
        }

        &::after {
            content: url('/assets/images/play-btn.svg');
            opacity: 1;

            @include respond(tab-land) {
                content: url('/assets/images/play-btn-tablet.svg');
            }

            @include respond(phone) {
                content: url('/assets/images/play-btn.svg');
            }
        }

        &__pulse {
            position: absolute;
            width: 27rem;
            height: 27rem;
            border-radius: 50%;
            border: 1px solid #ffffff;
            animation: scale-down-center 2s 0.3s infinite;
            z-index: 100;

            @include respond(tab-land) {
                width: 20rem;
                height: 20rem;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                width: 27rem;
                height: 27rem;
                border-radius: 50%;
                border: 1px solid #ffffff;
                animation: scale-down-center-third 2s 0.3s infinite;

                @include respond(tab-land) {
                    width: 20rem;
                    height: 20rem;
                }
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                width: 27rem;
                height: 27rem;
                border-radius: 50%;
                border: 1px solid #ffffff;
                animation: scale-down-center-sec 2s 0.3s infinite;

                @include respond(tab-land) {
                    width: 20rem;
                    height: 20rem;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        max-width: 36rem;
        width: 100%;

        @include respond(tab-land) {
            max-width: 45rem;
            margin-right: 6.6rem;
        }

        @include respond(phone) {
            order: 3;
        }

        &__text {
            font-family: 'Inter';
        }

        .btn-link {
            margin-top: 8rem;

            @include respond(tab-land) {
                margin-top: 6.3rem;
            }

            @include respond(phone) {
                margin-top: 4rem;
            }
        }
    }
}
